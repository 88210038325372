<template>
    <div>
        <div class="template-container">
            <div class="template-panel">
                <h3>My templates</h3>
                <input type="text" v-model="userFilter" placeholder="Filter..." class="filter-input">
                <div class="template-list">
                    <div v-for="template in filteredUserTemplates" :key="template.id" class="template-item">
                        <span class="edit-icon" @click="selectTemplate(template, 'user')">✏️</span>
                        <span class="template-text" :class="{'selected-template-text': editingTemplate === template.id}" @click="useTemplate(template)">
                          <b>{{ template.title }}</b><br>
                          {{ template.message }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="template-panel">
                <h3>Global templates</h3>
                <input type="text" v-model="globalFilter" placeholder="Filter..." class="filter-input">
                <div class="template-list">
                    <div v-for="template in filteredGlobalTemplates" :key="template.id" class="template-item">
                        <span v-if="state.isAdmin" class="edit-icon" @click="selectTemplate(template, 'global')">✏️</span>
                        <span class="template-text" :class="{'selected-template-text': editingTemplate === template.id}" @click="useTemplate(template)">
                          <b>{{ template.title }}</b><br>
                          {{ template.message }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <span class="template-input">
            <span class="template-stack">
              <input type="text" id="selectedTitle" v-model="selectedTitle" class="title-field" placeholder="Enter title" />
              <textarea v-model="selectedMessage" class="template-field" placeholder="Enter a new template message" rows="4" cols="140"></textarea>
            </span>
            <span v-if="state.isAdmin" ><input type="checkbox" v-model="isGlobal"> Global</span><br>
            <span v-if="selectedID === null" class="button-group">
                <button type="button" @click="submitTemplate">Add template</button>
            </span>
            <span v-else-if="selectedID !== null" class="button-group">
                <button type="button" @click="submitTemplate">Edit template</button> 
                <button type="button" @click="clearSelection">Clear</button> 
                <button v-if="showDeleteButton" type="button" @click="deleteTemplateSubmit">🗑️</button>
            </span>
        </span>
    </div>
</template>

<script>
import { ref, computed } from 'vue';
import { state } from '@/store.js';
import wsApi from '@/api/ws.js';

export default {
    setup() {
            const selectedMessage = ref('');
            const selectedTitle = ref('');
            const selectedID = ref(null);
            const submitAction = ref('create');
            const isGlobal = ref(false);
            const deleteTemplate = ref(false);
            const editingTemplate = ref(null);
            const userFilter = ref('');
            const globalFilter = ref('');

            const filteredUserTemplates = computed(() => {
                if (state.templates.user) {
                    return state.templates.user.filter(template =>
                        template.title
                        ? template.title.toLowerCase().includes(userFilter.value.toLowerCase())
                        : false
                    );
                }
                return [];
            });

            const filteredGlobalTemplates = computed(() => {
                if (state.templates.global) {
                    return state.templates.global.filter(template =>
                        template.title
                        ? template.title.toLowerCase().includes(globalFilter.value.toLowerCase())
                        : false
                    );
                }
                return [];
            });

            const showDeleteButton = computed(() => {
            return !isGlobal.value || (isGlobal.value && state.isAdmin);
        });

        return {
            selectedMessage,
            selectedTitle,
            selectedID,
            submitAction,
            isGlobal,
            deleteTemplate,
            showDeleteButton,
            editingTemplate,
            state,
            userFilter,
            globalFilter,
            filteredUserTemplates,
            filteredGlobalTemplates
        };
    },
    methods: {
        selectTemplate(template, type) {
            this.selectedTitle = template.title;
            this.selectedMessage = template.message;
            this.selectedID = template.id;
            this.submitAction = 'edit';
            this.isGlobal = (type === 'global');
            this.editingTemplate = template.id;
        },
        useTemplate(template) {
            state.mainMessage = template.message;
            state.showTemplateModal = false;
        },
        submitTemplate() {
            var action = this.selectedID ? 'update' : 'create';
            if (this.deleteTemplate === true) {
                action = 'delete';
            }
            var payload = {
                action: 'crudTemplate',
                content: {
                    title: this.selectedTitle,
                    message: this.selectedMessage,
                    action: action,
                    global: this.isGlobal,
                    id: this.selectedID
                }
            };
            wsApi.send(JSON.stringify(payload));
            this.clearSelection();
        },
        deleteTemplateSubmit() {
            this.deleteTemplate = true;
            this.submitTemplate();
        },
        clearSelection() {
            this.selectedMessage = '';
            this.selectedTitle = '';
            this.selectedID = null;
            this.submitAction = 'create';
            this.isGlobal = false;
            this.deleteTemplate = false;
            this.editingTemplate = null;
        }
    },
};
</script>

<style scoped>
.template-container {
  padding-right: 0;
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
}

.template-panel {
  flex: 1;
  background-color: #4682b4;
  color: #fff;
  margin: 0 10px;
  border-radius: 5px;
  overflow-y: auto;
  max-height: 300px;
  min-width: 400px;
  max-width: 50%;
  padding: 10px;
}

.template-panel h3 {
  margin-top: 0;
  color: #fff;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.template-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.template-list li {
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.template-list li:hover {
  background-color: #e6f7ff; 
}

textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.template-field, .title-field {
background-color: #4682b4;
border: 1px solid #fff;
border-color: #fff;
padding: 10px 15px;
border-radius: 5px;
color: #fff;
width: 400px;
}
.template-field::placeholder, .title-field::placeholder {
color: #fff;
opacity: 1; /* Firefox */
}

button {
  background-color: #4682b4;
  border: 1px solid #ffffff; 
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #224e72;
}

.button-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.template-input {
  display: flex;
  align-items: center;
  gap: 10px;
}

.template-stack {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.template-item {
  display: flex;
  align-items: center;
  overflow: hidden;
  line-height: 2.5em;
  font-size: 12px;
}

.edit-icon {
  cursor: pointer;
  margin-right: 10px;
}

.template-text, .selected-template-text {
  flex-grow: 1;
  flex-shrink: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  font-size: 12px;
}

.template-text:hover, .selected-template-text {
  background-color: #224e72;
}

.edit-icon {
  cursor: pointer;
  margin-left: 10px;
}

.filter-input {
  background-color: #4682b4;
  border: 1px solid #fff;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  margin-bottom: 10px;
  width: calc(100% - 20px);
}

.filter-input::placeholder {
  color: #fff;
  opacity: 1;
}
</style>