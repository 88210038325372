import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from '@/views/LoginPage.vue';
import MainApp from '@/views/MainApp.vue';
import { nextTick } from 'vue';

const routes = [
    {
        path: '/login',
        component: LoginPage
    },
    {
        path: '/',
        component: MainApp
    },
    {
        path: '/',
        redirect: '/login'
    }
];  

const router = createRouter({
    history: createWebHistory(),
    routes
  });

const DEFAULT_TITLE = 'ARS Text-em-Back';
router.beforeEach(async (to, _, next) => {
    if (to.path === '/' && !await isAuthenticated()) {
        next('/login');
    } else if (to.path === '/login' && await isAuthenticated()) {
        next('/');
    } else {
        next();
    }
    nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});

async function isAuthenticated() {
    try {
      const response = await fetch('/authed');
      return response.status === 200;
    } catch (error) {
      console.error('Error checking authentication:', error);
      return false;
    }
}

export default router;