<!-- src/components/UserHeader.vue -->
<template>
  <span id="right-header">
    <span id="username" :data-username="state.username" class="user-text">{{ state.username }}</span>
    <span v-if="state.isAdmin" class="owner-text"> (Administrator)</span><br>
    <span v-if="state.isAdmin" class="user-text"><a  @click="showGuests = true">Manage guests</a><br></span>

    <AppModal :show="showGuests" @close="showGuests = false">
      <div>
        <h1>Guest user management</h1>
        <hr />
        <form @submit.prevent="addGuest" class="form-group">
          <div class="input-group">
            <input v-model="newGuest.name" id="name" placeholder="Name" required />
          </div>
          <div class="input-group">
            <input v-model="newGuest.email" id="email" placeholder="Email" required />
          </div>
          <button type="submit">Add Guest</button>
          <input type="file" @change="uploadCSV" accept=".csv" />
        </form>
        <hr />
        <div v-if="error" class="error">{{ error }}</div>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="guest in guests" :key="guest.email">
              <td>{{ guest.name }}</td>
              <td>{{ guest.email }}</td>
              <td><button @click="deleteGuest(guest.email)">❌</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </AppModal>

    <span id="connectionIndicator" class="connection-indicator">
      {{ connectionIcon }} {{ connectionStatus }} -- <a href="/logout" class="logout-link">Logout</a>
    </span><br>
    <span id="reloadElement" class="reload-element">
      <a class="clickable-emoji" id="reloadButton" hidden>🔄 New version</a>
    </span>
  </span>
  <div class="headbanner">
    <h1>
      Text-em-Back
    </h1>
    <h4>
      {{ state.instanceName }}
    </h4>
  </div>
</template>

<script>
import axios from 'axios';
import wsApi from '@/api/ws.js';
import { state } from '@/store.js';
import AppModal from '@/components/AppModal.vue';
export default {
  name: 'UserHeader',
  components: {
    AppModal
  },
  data() {
    return {
      guests: [],
      newGuest: {
        name: '',
        email: ''
      },
      error: '',
      connectionIcon: '🔴',
      connectionStatus: 'Disconnected',
      idleTimer: null,
      showGuests: false,
      state
    };
  },
  computed: {
    ws() {
      return wsApi.getWebSocket();
    }
  },
  methods: {
    updateConnectionIndicator(icon, status) {
      this.connectionIcon = icon;
      this.connectionStatus = status;
    },
    resetIdleTimer() {
      clearTimeout(this.idleTimer);
      this.idleTimer = setTimeout(() => {
        wsApi.send(JSON.stringify({ action: 'syn' }));
      }, 20000);
    },
    async fetchGuests() {
      try {
        const response = await axios.get('/guest/admin');
        if (response.data !== null) {
          this.guests = response.data;
        } else {
          this.guests = [];
        }
      } catch (error) {
        console.error('Error fetching guests:', error);
        this.error = 'Error fetching guests';
        this.guests = [];
      }
    },
    async addGuest() {
      try {
        const response = await axios.post('/guest/admin', [this.newGuest]);
        if (response.status === 200) {
          this.fetchGuests();
          this.newGuest = { name: '', email: '' };
        } else {
          const errorText = await response.text();
          this.error = 'Error adding guest: ' + errorText;
        }
      } catch (error) {
        console.error('Error adding guest:', error);
        const errorText = error.response?.data || 'Unknown error occurred';
        this.error = errorText;
      }
    },
    async deleteGuest(email) {
    try {
        const response = await axios.delete('/guest/admin', {
          data: [{ email }]
        });
        if (response.status === 200) {
          this.fetchGuests();
        } else {
          const errorText = await response.text();
          this.error = 'Error deleting guest: ' + errorText;
        }
      } catch (error) {
          console.error('Error deleting guest:', error);
          const errorText = error.response?.data || 'Unknown error occurred';
          this.error = errorText;
      }
    },
    async uploadCSV(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = async (e) => {
        const csv = e.target.result;
        const data = this.parseCSV(csv);
        try {
          const response = await axios.post('/guest/admin', data);
          if (response.status === 200) {
            this.fetchGuests();
          } else {
            const errorText = await response.text();
            this.error = 'Error uploading CSV: ' + errorText;
          }
        } catch (error) {
          console.error('Error uploading CSV:', error);
          const errorText = error.response?.data || 'Unknown error occurred';
          this.error = errorText;
        }
      };
      reader.readAsText(file);
    },
    parseCSV(csv) {
      const lines = csv.split('\n');
      const result = [];
      const headers = lines[0].split(',').map(header => header.trim().toLowerCase());
      const regex = /(".*?"|[^",\s]+)(?=\s*,|\s*$)/g;
      for (let i = 1; i < lines.length; i++) {
          const line = lines[i].match(regex);
          if (line && line.length === headers.length) {
              const entry = {};
              for (let j = 0; j < headers.length; j++) {
                  entry[headers[j]] = line[j].replace(/(^"|"$)/g, '').trim();
              }
              if (entry.name && entry.email) {
                  result.push(entry);
              }
          }
      }
      return result;
    }
  },
  mounted() {
    wsApi.connectWebSocket();
  },
  created() {
    wsApi.on('messageReceived', () => {
      this.updateConnectionIndicator('🟢', 'Connected');
      // if (data.action === 'pong') {
      // }
      this.resetIdleTimer();
    });

    wsApi.on('open', () => {
      this.updateConnectionIndicator('🟢', 'Connected');
      this.resetIdleTimer();
    });

    wsApi.on('close', () => {
      this.updateConnectionIndicator('🔴', 'Disconnected');
    });

    wsApi.on('error', () => {
      this.updateConnectionIndicator('🔴', 'Error - Reconnecting...');
    });

    this.fetchGuests();
  },
  beforeUnmount() {
    wsApi.off('messageReceived');
    wsApi.off('open');
    wsApi.off('close');
    wsApi.off('error');
    clearTimeout(this.idleTimer);
  }
}
</script>

<style scoped>
body {
  height: 100%;
  margin: 0;
  font-family: 'Victor Mono', monospace;
  font-weight: 100;
}

.headbanner {
  width: 100%;
  height: 10vh;
  display: flex;
  font-family: 'Victor Mono', monospace;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #fff;
  text-shadow: -1px -1px 0 #2781ca, 1px -1px 0 #2781ca, -1px 1px 0 #2781ca, 1px 1px 0 #2781ca;
  background: url("/public/banner.png") no-repeat center center;
  background-size: cover;
  overflow: hidden;
}
.headbanner h1, .headbanner h4 {
    margin: 0;
    padding: 0;
    line-height: 1.1;
}

.headbanner h1 {
  font-size: 24px;
}
.headbanner h4 {
  font-size: 14px;
}

.user-text {
  text-decoration: none;
  color: #000;
  font-size: 10px;
}

.user-text a:link {
  text-decoration: underline;
  cursor: pointer;
}

.logout-link {
  font-size: 9px;
  text-align: left;
  color: #000;
}

.reload-element {
  font-size: 9px;
  text-align: left;
  color: #000;
}

.connection-indicator {
  font-size: 9px;
  text-align: left;
  color: #000;
}

#right-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 8px;
  text-align: right;
  width: 100%;
}

.username {
  padding-top: 10px;
  font-weight: bold;
  font-size: 10px;
  font-family: 'Victor Mono', monospace;
}

.owner-text {
  text-decoration: italic;
  color: #727272;
  font-size: 10px;
}

.clickable-emoji, .emoji-overlay {
  cursor: pointer;
  top: 0;
  left: 0;
}


.form-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.input-group {
  flex-grow: 1;
  max-width: calc(50% - 20px);
  display: flex;
  flex-direction: column;
}

.input-group input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button[type="submit"], button[type="button"] {
  padding: 10px;
  border: none;
  border-radius: 4px;
  color: #fff;
  background-color: #7EB2CF;
  cursor: pointer;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #2781ca;
}

.error {
  color: red;
  margin-top: 20px;
}
</style>
