<!-- src/components/ChatWindow.vue -->
<template>
  <div class="chat">
    <div class="chat-title-bar">
      <div class="left-info-container">

        <span class="chat-info">
            Outgoing number: 
            <span class="num-select" id="numSelect">
                <template v-if="state.outgoingNumbers && typeof state.outgoingNumbers === 'object' && Object.keys(state.outgoingNumbers).length > 1">
                    <select class="num-selector" v-model="state.selectedOutgoing" @change="handleNumberChange">
                        <option v-for="(number, label) in state.outgoingNumbers" :key="number" :value="number">{{ label }} ({{ number }})</option>
                    </select>
                </template>
                <template v-else-if="state.outgoingNumbers && typeof state.outgoingNumbers === 'object'">
                    {{ Object.values(state.outgoingNumbers)[0] }}
                </template>
            </span>
        </span>

        <span id="woInfo" class="wo-info">
          <template v-if="state.chatTitleBar.wos && state.chatTitleBar.wos.length">
            Associated WOs:
            <span v-for="(wo, index) in state.chatTitleBar.wos" :key="wo">
              <a 
                :href="'https://gate.ars-janus.com/workOrder/detail/' + wo + '/overview'" 
                target="_blank">
                {{ wo }}
              </a>
              <span v-if="index !== state.chatTitleBar.wos.length - 1">, </span>
            </span>
          </template>
        </span>
      </div>
        
      <span id="chatTitleBar" class="chat-title-text">
        <template v-if="state.chatTitleBar.label && state.chatTitleBar.phone">
          Chat with {{ state.chatTitleBar.label }} 
          <template v-if="(state.chatTitleBar.phone + '').length < 8 && !state.chatTitleBar.isGroup && state.instanceName !== 'Onboarding'">
            (<a :href="'https://gate.ars-janus.com/technician/detail/' + state.chatTitleBar.phone + '/overview'" target="_blank">{{ state.chatTitleBar.phone }}</a>)
          </template>
          <template v-else-if="(state.chatTitleBar.phone + '').length < 8 && !state.chatTitleBar.isGroup && state.instanceName === 'Onboarding'">
            (<a :href="'https://gate.ars-janus.com/candidate/technician/detail/' + state.chatTitleBar.phone" target="_blank">{{ state.chatTitleBar.phone }}</a>)
          </template>
          <template v-else-if="!state.chatTitleBar.isGroup">
            (<a :href="'tel:' + state.chatTitleBar.phone ">{{ state.chatTitleBar.phone }}</a>)
          </template>
        </template>
        <template v-else-if="state.chatTitleBar.phone">
         <template v-if="(state.chatTitleBar.phone + '').length < 8 && !state.chatTitleBar.isGroup && state.instanceName !== 'Onboarding'">
            (<a :href="'https://gate.ars-janus.com/technician/detail/' + state.chatTitleBar.phone + '/overview'" target="_blank">{{ state.chatTitleBar.phone }}</a>)
          </template>
         <template v-if="(state.chatTitleBar.phone + '').length < 8 && !state.chatTitleBar.isGroup && state.instanceName === 'Onboarding'">
            (<a :href="'https://gate.ars-janus.com/candidate/technician/detail/' + state.chatTitleBar.phone" target="_blank">{{ state.chatTitleBar.phone }}</a>)
          </template>
          <template v-else-if="!state.chatTitleBar.isGroup">
            (<a :href="'tel:' + state.chatTitleBar.phone ">{{ state.chatTitleBar.phone }}</a>)
          </template>
        </template>
      </span>

    <span class="chat-options" id="chatOptions" v-if="state.chatTitleBar.phone">
      <br><br>
      <span class="chat-option">
        <a id="saveChat">
          <span :style="{ filter: isChatSaved ? 'grayscale(100%)' : 'grayscale(0%)' }">📌 </span>
          <span v-if="isChatSaved" 
                @click="unsaveChat(state.chatTitleBar.phone)" 
                :title="'Remove from saved chats'">
            Unsave
          </span>
          <span v-else 
                @click="saveChat(state.chatTitleBar.phone)" 
                :title="'Add to saved chats'">
            Save
          </span>
        </a>
      </span>
      <span class="chat-option"></span>
    </span>

    

      <AppModal :show="state.showNoteModal" @close="state.showNoteModal = false" style="overflow: initial;" >
        <h2>Add note</h2>
        <span class="note-container">
          <textarea v-model="noteContent" @input="onTagInput" @keydown.down.prevent="navigateSuggestions(1)" @keydown.up.prevent="navigateSuggestions(-1)" @keydown.enter.prevent="selectSuggestion()" name="noteContent" rows="10" cols="90" class="note-field" required></textarea>
            <ul v-if="showSuggestions" class="suggestions">
              <li v-for="(user, index) in filteredUsers" :key="user" :class="{ 'active': index === activeSuggestion }" @click="selectSuggestion(user)">{{ user }}</li>
            </ul>
        </span>
        <br>
        <button @click="addNote" class="modal-button">Submit</button><br>
      </AppModal>

      <AppModal :show="state.showTemplateModal" @close="state.showTemplateModal = false" style="overflow: initial;">
        <TemplateSelector />
      </AppModal>

    </div>
    <div ref="chatWindow" id="chatWindow" class="chat-window" @drop.prevent="handleDrop" @dragover.prevent @dragenter.prevent>
      <ul id="chatWindow" class="chat-list">

      <li v-if="state.chatTitleBar.isGroup && currentGroup">
        <div class="chat-line group">
          <br>
          <div class="note-header">
            Message group containing 
            <span v-for="(prsn, index) in currentGroup.members" :key="index">
              {{ prsn.name }} ({{ prsn.number }})
              <span v-if="index < currentGroup.members.length - 1">, </span>
            </span>
          </div>
          <br>
        </div>
      </li>

        <li v-for="msg in chatMessages" :key="msg.timestamp">

            <div v-if="msg.type === 'noChat'" class="no-chat-message">👻 No chat history</div>

            <div v-else-if="msg.type === 'sent'" class="chat-line sent">
                <div class="chat-pin">
                    <span class="clickable-emoji" 
                          :style="msg.pinned ? 'filter:grayscale(0%) opacity(1);' : 'filter:grayscale(100%) opacity(20%);'" 
                          @click="pinMsg(!msg.pinned, msg.sid, msg.timestamp)">
                        📌
                    </span>
                    <div v-if="msg.pinned">Pinned by {{ msg.pinned }}</div>
                </div>
                <div v-html="msg.message"></div>
                <div class="username">{{ msg.username }}</div>
                <div class="timestamp-light">
                    <div v-html="msg.sender"></div>
                    {{ getTime(msg.timestamp) }}
                </div>
            </div>

            <div v-else-if="msg.type === 'received'" class="chat-line received">
              <div class="chat-pin">
                  <span class="clickable-emoji" 
                        :style="msg.pinned ? 'filter:grayscale(0%) opacity(1);' : 'filter:grayscale(100%) opacity(20%);'" 
                        @click="pinMsg(!msg.pinned, msg.sid, msg.timestamp)">
                      📌
                  </span>
                  <div v-if="msg.pinned">Pinned by {{ msg.pinned }}</div>
              </div>
                <div v-html="msg.message"></div>
                <div class="timestamp-dark">
                    <div v-html="msg.sender"></div>
                    {{ getTime(msg.timestamp) }}
                </div>
            </div>

            <div v-else-if="msg.type === 'note'" class="sticky-note">
                <div class="chat-pin">
                    <span class="clickable-emoji" 
                          :style="msg.pinned ? 'filter:grayscale(0%) opacity(1);' : 'filter:grayscale(100%) opacity(20%);'" 
                          @click="pinMsg(!msg.pinned, msg.sid, msg.timestamp)">
                        📌
                    </span>
                    <div v-if="msg.pinned">Pinned by {{ msg.pinned }}</div>
              </div>
              <div class="note-header-dark">
                Internal note
              </div>
              <div v-html="msg.message"></div>
              <span class="note-metadata">
                <div class="username">
                  {{ msg.username }}
                </div>
                <div class="timestamp-dark">
                  {{ getTime(msg.timestamp) }}
                </div>
              </span>
            </div>
            <div v-else-if="msg.type === 'group'" class="chat-line group">
              <div class="note-header">
                Text blast
              </div>
              <div v-html="msg.message"></div>
              <div class="username">
                <div v-html="msg.username"></div>
              </div>
              <div class="timestamp-light">
                <div v-html="msg.sender"></div>
                {{ getTime(msg.timestamp) }}
              </div>
            </div>
            <div v-else-if="msg.type === 'error'" class="chat-line error">
              <div class="note-header">
                Failed to send!
              </div>
              <div v-html="msg.message"></div>
              <div class="username">
                {{ msg.sender }}
              </div>
              <div class="timestamp-dark">
                {{ getTime(msg.timestamp) }}
              </div>
            </div>
        </li>
      </ul>
    </div>
    <div class="upload-input" v-if="state.showUploadPreview">
      <img :src="state.imagePreviewSrc" class="preview-image" />
      <span class="close-upload" @click="clearImageUpload">&times;</span>
    </div>
    <div class="chat-input">
    <input type="file" id="fileInput" accept="image/*" ref="fileInput" @change="handleImageSelect" style="display: none;" />
    <button id="uploadButton" class="upload-button" @click="uploadImage" >📷</button>
    <button id="templateButton" class="upload-button" @click="state.showTemplateModal = true">⚡</button>
    <button id="templateButton" class="upload-button" @click="state.showNoteModal = true">📔</button>
    <textarea 
        id="message" 
        rows="2" 
        placeholder="[Shift-enter for new line]"
        v-model="state.mainMessage"
        @keydown.enter.exact.prevent="sendMessage"
        @keydown.shift.enter="insertLineBreak"
        @drop.prevent="handleDrop" 
        @dragover.prevent 
        @dragenter.prevent
        @paste="handlePaste"
    ></textarea>
    <button id="sendButton" class="send-button" @click="sendMessage">Send</button>
    </div>
  </div>
</template>

<script>
import wsApi from '@/api/ws.js';
import { state, saveChat, unsaveChat, isChatSaved, updateLastViewed, updateLastViewedTimestamp, updateTemplates } from '@/store.js';
import main from '@/main.js';
import AppModal from '@/components/AppModal.vue';
import TemplateSelector from '@/components/TemplateSelector.vue';
import { ref } from 'vue';

export default {
  components: {
    AppModal,
    TemplateSelector
  },
  data() {
      return {
        chatMessages: [],
        showSuggestions: false,
        filteredUsers: [],
        activeSuggestion: 0
      };
  },
  computed: {
    currentGroup() {
      const foundGroup = this.groupBook.find(group => group.name === this.state.chatTitleBar.label);
      return foundGroup || null;
    }
  },
  setup(props, { emit }) {
    // const chatWindow = ref(null);
    const noteContent = ref('');
    const groupBook = ref(JSON.parse(localStorage.getItem('groupBook')) || []);

    const addNote = () => {
      if (noteContent.value.trim() !== '') {
        wsApi.send(JSON.stringify({
          action: 'addNote',
          content: {
            phone_number: `${state.chatTitleBar.phone}`,
            message: noteContent.value
          }
        }));
        noteContent.value = '';
        emit('close');
        state.showNoteModal = false;
      }
    };

    const uploadImage = () => {
      if ((state.selectedPhoneNumber !== '') && (state.selectedPhoneNumber !== null)) {
        document.getElementById('fileInput').click();
      }
    };

    const clearImageUpload = () => {
      state.uploadedImageUrl = null;
      state.imagePreviewSrc = '';
      state.showUploadPreview = false;
    };

    const sendMessage = () => {
      var messageInput = document.getElementById('message');
      var message = messageInput.value;
      if (state.chatTitleBar.isGroup) {
        const groupName = state.chatTitleBar.label;
        const payload = {
          action: state.uploadedImageUrl ? 'groupMMS' : 'groupMsg',
          content: {
            group_name: groupName,
            twilio_number: state.selectedOutgoing,
            message: message
          }
        };
        if (state.uploadedImageUrl) payload.content.url = state.uploadedImageUrl;
        wsApi.send(JSON.stringify(payload));
        wsApi.loadChat(groupName);
        messageInput.value = '';
        if (state.uploadedImageUrl) {
          clearImageUpload()
        }
        state.mainMessage = '';
      } else {
        if ((message && state.selectedPhoneNumber) || (state.uploadedImageUrl && state.selectedPhoneNumber)) {
          if (state.selectedPhoneNumber.length === 10) {
            state.selectedPhoneNumber = "+1" + state.selectedPhoneNumber;
          }
          const payload = {
            action: state.uploadedImageUrl ? 'sendMMS' : 'sendMsg',
            content: {
              phone_number: state.selectedPhoneNumber,
              twilio_number: state.selectedOutgoing,
              message: message
            }
          };
          if (state.uploadedImageUrl) payload.content.url = state.uploadedImageUrl;
          wsApi.send(JSON.stringify(payload));
          messageInput.value = '';
          if (state.uploadedImageUrl) {
            clearImageUpload()
          }
          state.mainMessage = '';
        }
      }
    };


    const insertLineBreak = (event) => {
      const textarea = event.target;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      textarea.value = textarea.value.substring(0, start) + "\n" + textarea.value.substring(end);
      textarea.selectionStart = textarea.selectionEnd = start + 1;
      event.preventDefault();
    };

    const uploadFiles = (files) => {
      if (files && files[0]) {
        state.imagePreviewSrc = 'https://ars-sms.s3.amazonaws.com/throbber.gif';
        const formData = new FormData();
        formData.append('image', files[0]);
        fetch(state.apiUrl + 'upload', {
            method: 'POST',
            body: formData
        })
        .then(response => response.json())
        .then(data => {
            state.imagePreviewSrc = data.url;
            state.showUploadPreview = true;
            state.uploadedImageUrl = data.url;
        })
        .catch(error => {
            state.imagePreviewSrc = 'Error uploading image '+error;
            state.showUploadPreview = false;
        });
      } else {
          console.log("Couldn't upload file:", files);
      }
    };

    const handleDrop = (event) => {
      const files = event.dataTransfer.files;

      if (files.length > 0) {
          uploadFiles(files);
      }
    };

    return {
      state,
      saveChat,
      unsaveChat,
      isChatSaved,
      uploadImage,
      sendMessage,
      insertLineBreak,
      updateLastViewedTimestamp,
      updateLastViewed,
      noteContent,
      addNote,
      clearImageUpload,
      groupBook,
      handleDrop,
      uploadFiles
    };
  },

  methods: {

    handleImageSelect() {
      const fileInput = this.$refs.fileInput; // Using refs instead of getElementById
      if (fileInput.files && fileInput.files[0]) {
        console.log("File is selected and exists");
        state.imagePreviewSrc = 'https://ars-sms.s3.amazonaws.com/throbber.gif';
        const formData = new FormData();
        formData.append('image', fileInput.files[0]);
        fetch(state.apiUrl + 'upload', {
            method: 'POST',
            body: formData
        })
        .then(response => response.json())
        .then(data => {
            state.imagePreviewSrc = data.url;
            state.showUploadPreview = true;
            state.uploadedImageUrl = data.url;
        })
        .catch(error => {
            state.imagePreviewSrc = 'Error uploading image '+error;
            state.showUploadPreview = false;
        });
      } else {
        console.log("Couldn't upload file:", fileInput.files);
      }
    },

    handleNumberChange(event) {
      state.selectedPhoneNumber = event.target.value;
      localStorage.setItem('selectedOutgoing', state.selectedOutgoing);
      if ((state.chatTitleBar.label) && (state.chatTitleBar.phone)) {
          wsApi.loadChat(state.chatTitleBar.phone);
      }
    },

    scrollChatToBottom() {
        this.$nextTick(() => {
            this.$refs.chatWindow.scrollTop = this.$refs.chatWindow.scrollHeight;
            setTimeout(() => {
                this.$refs.chatWindow.scrollTop = this.$refs.chatWindow.scrollHeight;
            }, 250);
        });
    },

    inSound(event) {
      if (event === 'received') {
        let audio = new Audio('/plop.mp3');
        audio.play();
      } else if (event === 'sent') {
        let audio = new Audio('/bonk.mp3');
        audio.play();
      } else if (event === 'error') {
        let audio = new Audio('/error.mp3');
        audio.play();
      } else {
        return
      }
    },

    convertLinks(text) {
      // linebreak newlines
      text = text.replace(/\n/g, '<br>');
      // bold user tags
      text = text.replace(/@"([a-zA-Z0-9._, ]+)"/g, '<b>$1</b>');
      // detect web links
      var regex = /(https?:\/\/[^\s]+)/g;
      return text.replace(regex, function(url) {
        // detect image links
          var imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp', '.bmp', '.tiff', '.ico', '.jfif'];
          for (var i = 0; i < imageExtensions.length; i++) {
              if (url.indexOf(imageExtensions[i]) !== -1) {
                  return '<a href="' + url + '" target="_blank"><img src="' + url + '" alt="Image" style="max-width:400px;"></a>';
              }
          }
          return '<a href="' + url + '" target="_blank">' + url + '</a>';
      });
    },

    renderChat(content) {
      if (!content || content.length === 0) {
        this.chatMessages = []; 
        this.chatMessages.push({
            type: 'noChat',
            content: '👻 No chat history'
        });
      } else if (content.action === 'sendMsg') {
        // render individual incoming messages
        let type = '';
        let msgContent = '';
        if (content.content.sid === "note") {
            type = 'note';
        // } else if (chatItem.sid === "group" && chatItem.sender === state.selectedOutgoing) {
        } else if (content.content.sid === "group") {
            type = 'group';
        } else if (content.content.sid === "error") {
            type = 'error';
            this.inSound('error');
        // } else if (!chatItem.username && chatItem.recipient === state.selectedOutgoing) {
        } else if (!content.content.username) {
            type = 'received';
            this.inSound('received');
        // } else if (chatItem.sender === state.selectedOutgoing) {
        } else {
            type = 'sent';
            content.content.sender = state.selectedOutgoing;
            this.inSound('sent');
        }
        msgContent = this.convertLinks(content.content.message);
        this.chatMessages.push({
          type: type,
          message: msgContent,
          timestamp: content.content.timestamp,
          username: content.content.username,
          sender: content.content.sender
        });
      } else {
        // render conversations
          this.chatMessages = [];
          let unpinnedMessages = [];
          let pinnedMessages = [];
          content.forEach(chatItem => {
            if (chatItem.timestamp > this.latestTimestamp) {
                this.latestTimestamp = chatItem.timestamp;
            }
            let type = '';
            let msgContent = '';
            if (chatItem.sid === "note") {
                type = 'note';
            } else if (chatItem.sid === "group" && chatItem.sender === state.selectedOutgoing) {
            // } else if (chatItem.sid === "group") {
                type = 'group';
            } else if (chatItem.sid === "error") {
                type = 'error';
            } else if (!chatItem.username && chatItem.recipient === state.selectedOutgoing) {
            // } else if (!chatItem.username) {
                type = 'received';
            } else if (chatItem.sender === state.selectedOutgoing) {
            // } else {
                type = 'sent';
            }
            msgContent = this.convertLinks(chatItem.message);

            const messageObj = {
              type: type,
              message: msgContent,
              timestamp: chatItem.timestamp,
              username: chatItem.username,
              sender: chatItem.sender,
              recipient: chatItem.recipient,
              pinned: chatItem.pinned || null,
              sid: chatItem.sid
            };
            if (messageObj.pinned) {
                pinnedMessages.push(messageObj);
            } else {
                unpinnedMessages.push(messageObj);
            }
          });
        this.chatMessages = [...unpinnedMessages, ...pinnedMessages];
      }
    },

    handleMessageReceived(data) {
      switch (data.action) {
        case 'sessionInfo':
          state.isAdmin = data.content.isAdmin;
          state.username = data.content.username;
          state.outgoingNumbers = data.content.phones;
          state.outgoingNumberList = Object.values(data.content.phones);
          state.instanceName = data.content.instance;
          if (state.outgoingNumbers) {
            if (Object.keys(state.outgoingNumbers).length === 1) {
                state.selectedOutgoing = Object.values(state.outgoingNumbers)[0];
            }
            else if (localStorage.getItem('selectedOutgoing')) {
                state.selectedOutgoing = localStorage.getItem('selectedOutgoing');
            }
            else {
                state.selectedOutgoing = Object.values(state.outgoingNumbers)[0];
            }
          }
          break;
      case "loadChat":
        this.renderChat(data.content);
        break;
      // deprecated for incomingMsg
      case "sendMsg":
        if (state.selectedPhoneNumber === data.content.phone_number) {
          this.renderChat(data)
          this.updateLastViewedTimestamp(data.content.phone_number);
        }
        break;
      case 'loadSaved':
        if (!localStorage.savedChats) {
          localStorage.savedChats = JSON.stringify([]);
        }
        var savedChats = JSON.parse(localStorage.savedChats);
        data.content.forEach(function (chatNumber) {
          if (!savedChats.includes(chatNumber)) {
            savedChats.push(chatNumber);
          }
        });
        localStorage.savedChats = JSON.stringify(savedChats);
        break;
      case 'getUsers':
        localStorage.setItem('userList', JSON.stringify(data.content));
        data.content.forEach(function (user) {
          if (!state.userList.includes(user)) {
            state.userList.push(user);
          }
        });
        break;
      case "incomingMsg":
        var currentConvo = false;
        if (state.selectedPhoneNumber !== null && ((state.selectedPhoneNumber === data.content.from) || (state.selectedPhoneNumber === data.content.recipient)) && (data.content.recipient === state.selectedOutgoing || data.content.from === state.selectedOutgoing)) {
          currentConvo = true;
        }
        // if you have this convo open
        if (currentConvo) {
          if (data.content.sid === "error") {
              this.chatMessages.push({
                type: 'error',
                message: data.content.message,
                timestamp: main.getIsoTimestamp(),
                username: data.content.username,
                sender: data.content.sender
              });
              this.inSound('error');
              this.updateLastViewedTimestamp(state.selectedPhoneNumber)
              break;
          }
          wsApi.loadChat(state.selectedPhoneNumber);
          this.updateLastViewedTimestamp(state.selectedPhoneNumber);
          // not outgoing & not sent by you
          if ((data.content.from !== state.selectedPhoneNumber) && (data.content.sender === state.username)) {
              this.inSound('sent');
          // otherwise incoming
          } else if (data.content.from !== state.selectedOutgoing) {
              this.inSound('received');
              main.pushNotify(data.content.sender);
          }
        // if you have another convo selected
        } else if ((data.content.sender !== state.username) && (state.selectedOutgoing === data.content.recipient)) {
          main.pushNotify(data.content.sender);
          this.inSound('received');
        }
        break;
      case "loadNotifs":
        var newTags = data.content.tags.filter(tag => !state.tagList.includes(tag));
        var newItemsString = '';
        if (newTags.length > 0) {
          newItemsString = newTags.join(', ');
          main.tagNotify(newItemsString);
        }
        localStorage.setItem('tagList', JSON.stringify(data.content.tags));
        state.tagList = data.content.tags;
        break;
      case "loadReads":
        this.sortViews(data.content);
        break;
      case "loadTemplates":
        updateTemplates(data.content)
      }
    },

    getTime(timestamp) {
      if (timestamp == null) {
        timestamp = new Date().toISOString();
      }
      const dateObj = new Date(timestamp);
      const options = {
        weekday: "short",
        month: "short",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      var formattedDate = dateObj.toLocaleString("en-US", options);
      return formattedDate
    },

    sortViews(dataContent) {
      const result = {};
      const newestTime = (phone, timestamp) => {
        if (!result[phone]) {
          result[phone] = timestamp;
        } else {
          const existingTimestamp = new Date(result[phone]);
          const newTimestamp = new Date(timestamp);
          if (newTimestamp > existingTimestamp) {
            result[phone] = timestamp;
          }
        }
      };
      for (const [phone, timestamp] of Object.entries(dataContent)) {
        newestTime(phone, timestamp);
      }
      for (const [phone, timestamp] of Object.entries(state.lastViewed)) {
        newestTime(phone, timestamp);
      }
        this.updateLastViewed(result);
    },

    pinMsg(action, sid, timestamp) {
      var payload = {
          action: 'pinMsg',
          content: {
            action: action,
            sid: sid,
            timestamp: timestamp
          }
      };
      wsApi.send(JSON.stringify(payload));
      wsApi.loadChat(state.chatTitleBar.phone);
    },

    handlePaste(event) {
      const items = (event.clipboardData || event.originalEvent.clipboardData).items;
      let pastedFiles = [];
      for (let index in items) {
        let item = items[index];
        if (item.kind === 'file') {
          let blob = item.getAsFile();
          pastedFiles.push(blob);
        }
      }
      if (pastedFiles.length) {
        this.uploadFiles(pastedFiles);
        event.preventDefault();
      }
    },

    onTagInput(event) {
      const value = event.target.value;
      const lastAt = value.lastIndexOf('@');
      if (lastAt !== -1) {
        const query = value.slice(lastAt + 1);
        this.filteredUsers = state.userList.filter(user => user.toLowerCase().startsWith(query.toLowerCase()));
        this.showSuggestions = this.filteredUsers.length > 0;
        this.activeSuggestion = 0;
      } else {
        this.showSuggestions = false;
      }
    },

    navigateSuggestions(step) {
      if (this.showSuggestions) {
        this.activeSuggestion = (this.activeSuggestion + step + this.filteredUsers.length) % this.filteredUsers.length;
      }
    },

    selectSuggestion(user) {
      if (!user || user instanceof KeyboardEvent) {
        user = this.filteredUsers[this.activeSuggestion];
      }
      const value = this.noteContent;
      const lastAt = value.lastIndexOf('@');
      this.noteContent = value.slice(0, lastAt) + '@"' + user + '" ';
      this.showSuggestions = false;
    }

  },

  updated() {
    this.scrollChatToBottom();
  },

  mounted() {
    if (state.outgoingNumbers) {
      // If there's only one label-number pair, set it automatically.
      if (Object.keys(state.outgoingNumbers).length === 1) {
          state.selectedOutgoing = Object.values(state.outgoingNumbers)[0];
      }
      // If there are multiple label-number pairs and a selected number is stored in localStorage, use that.
      else if (localStorage.getItem('selectedPhoneNumber')) {
          state.selectedOutgoing = localStorage.getItem('selectedOutgoing');
      }
      // Otherwise, default to the first number in the list.
      else {
          state.selectedOutgoing = Object.values(state.outgoingNumbers)[0];
      }
    }
    wsApi.on('messageReceived', this.handleMessageReceived);
  },

  beforeUnmount() {
    wsApi.off('messageReceived', this.handleMessageReceived);
  }
}
</script>

<style scoped>
.chat {
  flex: 4;
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chat-title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #4682b4;
  height: 55px;
  color: #fff;
}

.chat-title-text {
  font-weight: bold;
  text-align: center;
  font-size: 16px;
  flex-grow: 1;
}

.chat-title-text a:link {
  text-decoration: underline;
  color: #fff;
}

.chat-title-text a:visited {
  text-decoration: underline;
  color: #fff;
}

.chat-title-text a:hover {
  text-decoration: none;
  color: #fff;
}

.chat-title-text a:active {
  text-decoration: none;
  color: #fff;
}

.left-info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.chat-info,
.wo-info {
  display: block;
  align-items: left;
  text-align: left;
  font-size: 12px;
  font-weight: normal;
}

.wo-info a:link {
  text-decoration: none;
  color: #fff;
}

.wo-info a:visited {
  text-decoration: none;
  color: #fff;
}

.wo-info a:hover {
  text-decoration: underline;
  color: #fff;
}

.wo-info a:active {
  text-decoration: underline;
  color: #fff;
}

.num-selector {
  display: inline-block;
  padding: 4px 10px;
  border: 1px solid #99b3c9;
  background-color: #529dd6;
  color: #fff;
  font-size: 10px;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  appearance: none;
  -webkit-appearance: none; 
  -moz-appearance: none;
  background-repeat: no-repeat;
  background-position: 95% center;
}

.chat-options {
  text-align: right;
  font-size: 12px;
  font-weight: normal;
  padding-right: 100px;
  cursor: pointer;
  /* z-index: 2; */
  white-space: nowrap;
}

.overlay-hidden {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  text-align: center;
  vertical-align: middle;
  line-height: 100vh;
  font-size: 2em;
  color: white;
}

.chat-window {
  font-family: sans-serif;
  flex: 9;
  overflow-y: scroll;
  border: 1px solid #99b3c9;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.2);
}

.upload-input {
  align-items: center;
  text-align: center;
  padding: 3px;
  border-top: 1px solid #99b3c9;
}

.close-upload {
  position: relative;
  right: 2px;
  top: 2px;
  align-items:center;
  text-align: center;
  cursor: pointer;
}

.chat-input {
  flex: 1;
  display: flex;
  align-items: left;
  padding: 3px;
  border-top: 1px solid #99b3c9;
}
.chat-input textarea {
  width: 90%;
  resize: vertical;
  background-color: #d3d3d3;
}
.chat-input textarea:hover {
  --alpha: 0.3;
}
.chat-input button {
  width: 10%;
}

.upload-button {
  top:50%;
  background-color:#2781ca;
  color: #fff;
  border: 1px solid #224e72; 
  border-radius:4px; 
  padding:4px;
  min-height:30px; 
  max-width: 40px;
}
.upload-button:hover {
  background-color:#224e72;
  transition: 0.1s;
  cursor: pointer;
}

.send-button {
  top:50%;
  background-color:#2781ca;
  color: #fff;
  border: 1px solid #224e72; 
  border-radius:4px; 
  padding:15px;
  min-height:30px; 
  min-width: 120px;
}
.send-button:hover {
  background-color:#224e72;
  transition: 0.1s;
  cursor: pointer;
}

.chat-line {
  position: relative;
  border-radius: 15px;
  margin: 5px;
  padding: 10px;
  max-width: 60%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.chat-line:hover .chat-pin {
  visibility: visible;
}

.sticky-note:hover .chat-pin {
  visibility: visible;
}

.chat-line a:link {
  text-decoration: none;
  color: #fff;
}

.chat-line a:visited {
  text-decoration: none;
  color: #fff;
}

.chat-line a:hover {
  text-decoration: underline;
  color: #fff;
}

.chat-line a:active {
  text-decoration: underline;
  color: #fff;
}

.chat-line.sent {
  background-color: #7AB8FD; 
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  float: right;
  text-align: right;
  clear: both;
}

.chat-line.sent a:link,
.chat-line.sent a:visited {
  text-decoration: underline;
  color: #fff;
}

.chat-line.received {
  background-color: #f2f2f2; 
  float: left;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  clear: both;
}

.chat-line.received a:link,
.chat-line.received a:visited {
  text-decoration: underline;
  color: #000;
}

.chat-line::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background: inherit;
  clip-path: polygon(-5px -5px, 100% 0, 0 100%);
  transform: rotate(45deg);
}

.chat-line.sent::after {
  right: -10px;
  bottom: 10px;
}

.chat-line.received::after {
  left: -10px;
  bottom: 10px;
}

.chat-line.note::after {
  right: -10px;
  bottom: 10px;
}

.chat-line.note {
  background-color: #ffe699; 
  color: #5A5A5A;
  padding-left: 20px;
  padding-right: 20px;
  float: right;
  text-align: right;
  clear: both;
}

.chat-line.note a:link,
.chat-line.note a:visited {
  text-decoration: none;
  color: #5A5A5A;
}

.chat-line.group::after {
  right: -10px;
  bottom: 10px;
}

.chat-line.group a:link,
.chat-line.group a:visited {
  text-decoration: none;
  color: #fff;
}


.chat-line.group {
  background-color: #5BC236; 
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  float: right;
  text-align: right;
  clear: both;
}

.chat-line.error::after {
  right: -10px;
  bottom: 10px;
}

.chat-line.error {
  background-color: #ffcccc; 
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  float: right;
  text-align: right;
  clear: both;
}

.chat-pin {
  text-align: left;
  text-decoration: italic;
  font-size: 9px;
  font-family: 'Victor Mono', monospace;
  line-height: 1.2em;
  visibility: hidden;
}

.no-chat-message {
  font-style: italic;
  color: gray;
}

.chat-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.chat-list > li {
  padding: 0;
  margin: 0;
}

.username {
  padding-top: 10px;
  font-weight: bold;
  font-size: 10px;
  font-family: 'Victor Mono', monospace;
}


.timestamp-dark {
  font-size: 10px;
  color: #808080;
  font-family: 'Victor Mono', monospace;
}

.timestamp-light {
  font-size: 10px;
  color: #dddddd;
  font-family: 'Victor Mono', monospace;
}

.note-header {
  color: #fff;
  font-style: italic;
  font-size: 11px;
  text-align: left;
}

.note-header-dark {
  color: #5A5A5A;
  font-style: italic;
  font-size: 11px;
  text-align: left;
}

.note-content {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
padding: 20px;
width: 450px;
height: 200px;
color: #fff;
background-color: #4682b4;
border: 3px solid #529dd6;
}

.modal-button {
display: inline-block;
padding: 10px 15px;
border: 1px solid #99b3c9;
background-color: #529dd6;
cursor: pointer;
color: #fff;
font-size: 14px;
border-radius: 5px;
}
.modal-button:hover {
background-color: #4682b4;
}

.note-field {
background-color: #4682b4;
border: 1px solid #fff;
border-color: #fff;
padding: 10px 15px;
border-radius: 5px;
color: #fff;
width: 400px;
}
.note-field::placeholder {
color: #fff;
opacity: 1; /* Firefox */
}

.preview-image {
  align-items:center;
  text-align: center;
  max-height: 300px;
  max-width: 30%;
}

.clickable-emoji {
cursor: pointer;
top: 0;
left: 0;
}

.sticky-note {
  width: 200px;
  min-height: 200px;
  height: auto;
  overflow: auto;
  background-color: #f8ee97;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  font-size: 12px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;
  overflow-wrap: break-word;
  right: 50px;
  bottom: 10px;
  margin-bottom: 20px;
  top: 10px;
  position: relative;
  color: #5A5A5A;
  padding-left: 20px;
  padding-right: 20px;
  float: right;
  text-align: right;
  clear: both;
}
.sticky-note::before {
  content: '';
  width: 50px;
  height: 10px;
  background-color: #FFF;
  opacity: 0.5;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
}

.note-metadata {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.note-container {
  position: relative;
}
.suggestions {
  position: absolute;
  top: 100%;
  left: 150px;
  transform: translateX(-50%);
  width: 200px;
  background-color: #529dd6;
  border: 2px solid #529dd6;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.suggestions li {
  padding: 8px 16px;
  width: 140px;
  cursor: pointer;
  list-style: none;
  border-bottom: 1px solid #d1e0e5;
}
.suggestions li:last-child {
  border-bottom: none;
}
.suggestions li:hover,
.suggestions li.active {
  background-color: #4682b4;
  color: white;
}

</style>