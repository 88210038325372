import { createApp } from 'vue'
import App from './App.vue'
import AppModal from '@/components/AppModal.vue';
import router from '@/router.js'
import { state } from '@/store.js';
import './registerServiceWorker.js'

const app = createApp(App);
app.component('AppModal', AppModal);
app.use(router);
app.mount('#app');

function getTimestamp(identifier) {
    var notifTimestamp = localStorage.getItem("notifTimestamp");
    if (notifTimestamp === null) {
        localStorage.setItem("notifs", '{}');
    }
    var notifs = JSON.parse(localStorage.getItem('notifs') || '{}');
    return notifs[identifier] || '0000-01-01T00:00:00Z';
}

function getIsoTimestamp() {
    var timestamp = new Date().toISOString();
    return timestamp;
}

function setTimestamp(identifier, timestamp) {
    var notifs = JSON.parse(localStorage.getItem('notifs') || '{}');
    notifs[identifier] = timestamp;
    localStorage.setItem('notifs', JSON.stringify(notifs));
}

function setNotifTimestamp()  {
    var timestamp = new Date().toISOString();
    state.latestTimestamp = timestamp;
    localStorage.setItem("notifTimestamp", timestamp);
}

function pushNotify(contact) {
    var notif = 'New message from ' + contact;
    if (Notification.permission === "granted") {
      new Notification("Text-em-Back", {
        body: notif,
      });
    }
    setNotifTimestamp();
}

function tagNotify(contact) {
  var notif = "You've been tagged in a conversation with " + contact;
  if (Notification.permission === "granted") {
    new Notification("Text-em-Back", {
      body: notif,
    });
  }
}

const main = {
  getTimestamp,
  getIsoTimestamp,
  setTimestamp,
  setNotifTimestamp,
  pushNotify,
  tagNotify
};

export default main;