<template>
<link rel="manifest" href="/manifest.json">
 <router-view></router-view>
  <div id="app" :key="reRender">
    <!-- <MainApp v-if="isAuthenticated.value" :key="isAuthenticated.value" />
    <LoginPage v-show="!isAuthenticated.value" /> -->
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
// import LoginPage from '@/views/LoginPage.vue';
// import MainApp from '@/views/MainApp.vue';

async function checkAuthenticated() {
  try {
    const response = await fetch('/authed');
    return response.status === 200;
  } catch (error) {
    console.error('Error checking authentication:', error);
    return false;
  }
}

export default {
  components: {
    // LoginPage,
    // MainApp
  },
  setup() {
    const isAuthenticated = ref(false);
    const reRender = ref(0);
    onMounted(async () => {
      isAuthenticated.value = await checkAuthenticated();
      console.log('isAuthenticated:', isAuthenticated.value);
      reRender.value++; 
    });

    return {
      isAuthenticated
    };
  }
}
</script>