import { reactive, computed } from 'vue';

// Initial state
export const state = reactive({
  apiUrl: process.env.VUE_APP_API_URL,
  username: null,
  isAdmin: null,
  selectedPhoneNumber: null,
  selectedOutgoing: null,
  selectedChat: 'contacts',
  selectedTechs: 'techs',
  uploadedImageUrl: null,
  latestTimestamp: null,
  imagePreviewSrc: '',
  instanceName: '',
  showUploadPreview: false,
  techsList: [],
  showTemplateModal: false,
  showNoteModal: false,
  mainMessage: '',
  //
  contactsFilter: "",
  ownerFilter: "",
  groupFilter: "",
  //
  chatTitleBar: {
    label: "",
    phone: "",
    wos: [],
    isGroup: false
  },
  outgoingNumbers: JSON.parse('{"loading":"..."}'),
  outgoingNumberList: [],
  groupBook: JSON.parse(localStorage.getItem('groupBook')) || [],
  contactsBook: JSON.parse(localStorage.getItem('contactsBook')) || [],
  savedChats: JSON.parse(localStorage.getItem('savedChats')) || [],
  lastViewed: JSON.parse(localStorage.getItem('lastViewed')) || {},
  userList: JSON.parse(localStorage.getItem('userList')) || [],
  tagList: JSON.parse(localStorage.getItem('tagList')) || [],
  templates: {
    user: [],
    global: []
  }
});

export function saveChat(phoneNumber) {
  if (!state.savedChats.includes(phoneNumber.toString())) {
    state.savedChats.push(phoneNumber.toString());
    localStorage.setItem('savedChats', JSON.stringify(state.savedChats));
  }
}

export function unsaveChat(phoneNumber) {
  const index = state.savedChats.indexOf(phoneNumber.toString());
  if (index > -1) {
    state.savedChats.splice(index, 1);
    localStorage.setItem('savedChats', JSON.stringify(state.savedChats));
  }
}

export const isChatSaved = computed(() => {
  return state.savedChats.includes(state.chatTitleBar.phone.toString());
});

export function updateTechBook(content) {
  localStorage.setItem('techBook', JSON.stringify(content));
  state.techsList = content;
}

export function updateTemplates(content) {
  localStorage.setItem('templates', JSON.stringify(content));
  state.templates = content;
}

export const modalTechsList = computed(() => {
  return JSON.parse(localStorage.getItem('techBook')) || [];
});

export function updateLastViewed(content) {
  localStorage.setItem('lastViewed', JSON.stringify(content));
  state.lastViewed = content;
}

export function updateLastViewedTimestamp(id) {
  if (!localStorage.getItem('lastViewed')) {
    localStorage.setItem('lastViewed', JSON.stringify({}));
  }
  const lastViewed = JSON.parse(localStorage.getItem('lastViewed'));
  const updatedTimestamps = { ...lastViewed, [id]: new Date().toISOString() };
  localStorage.setItem('lastViewed', JSON.stringify(updatedTimestamps));
  state.lastViewed = updatedTimestamps;
}

export function clearNotifications() {
  if (!localStorage.getItem('lastViewed')) {
    localStorage.setItem('lastViewed', JSON.stringify({}));
  }
  const currentTimestamp = new Date().toISOString();
  let lastViewed = JSON.parse(localStorage.getItem('lastViewed') || '{}');
  const techs = JSON.parse(localStorage.getItem('techBook') || '[]');
  for (let tech of techs) {
    if (tech.timestamp && (!lastViewed[tech.tech_id.toString()] || tech.timestamp > lastViewed[tech.tech_id.toString()])) {
      lastViewed[tech.tech_id.toString()] = currentTimestamp;
    }
  }
  const contacts = JSON.parse(localStorage.getItem('contactsBook') || '[]');
  for (let contact of contacts) {
    if (contact.timestamp && (!lastViewed[contact.phone] || contact.timestamp > lastViewed[contact.phone])) {
      lastViewed[contact.phone] = currentTimestamp;
    }
  }
  localStorage.setItem('lastViewed', JSON.stringify(lastViewed));
  if (Notification.permission !== "granted") {
    Notification.requestPermission();
  }
}