<template>
  <div v-if="show" class="modal-overlay" @click="closeModal">
    <div class="modal-content" @click.stop v-bind="$attrs">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppModal',
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6;
}

.modal-content {
  background-color: #529dd6;
  color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: auto;
  min-width: 10%;
  max-width: 60%;
  max-height: 70%;
  overflow: auto;
  z-index: 11;
}
</style>
