<template>
  <body>
    <div class="main-app">
      <UserHeader :userName="userName" :isAdmin="isAdmin" />
      <div class="container">
        <ContactsList />
        <ChatWindow />
        <TechsList />
      </div>
    </div>
  </body>
</template>

<script>
import UserHeader from '@/components/UserHeader.vue';
import ContactsList from '@/components/ContactsList.vue';
import ChatWindow from '@/components/ChatWindow.vue';
import TechsList from '@/components/TechsList.vue';
import wsApi from '@/api/ws.js';

export default {
  components: {
    UserHeader,
    ChatWindow,
    ContactsList,
    TechsList
  },
  setup() {
    const actionRequest = {
      action: 'Init'
    };
    wsApi.send(JSON.stringify(actionRequest));
  },
  data() {
    return {
      // your reactive data properties
    };
  },
  methods: {
    // methods to handle events, etc.
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Victor+Mono&display=swap');

body {
  height: 100%;
  margin: 0;
  font-family: 'Victor Mono', monospace;
  font-weight: 100;
}

.container {
  display: flex;
  height: 90vh;
  width: 100%;
  background-color: #99b3c9;
}

.owner-text {
  text-decoration: italic;
  color: #727272;
  font-size: 10px;
}

.contacts::-webkit-scrollbar,
.techs::-webkit-scrollbar,
.column-content::-webkit-scrollbar,
.chat-window::-webkit-scrollbar,
.filterList::-webkit-scrollbar,
.number-tags::-webkit-scrollbar,
.edit-column::-webkit-scrollbar,
.modal-content::-webkit-scrollbar,
.edit-assign::-webkit-scrollbar,
.own-modal-list::-webkit-scrollbar,
.dropdown::-webkit-scrollbar,
.template-list::-webkit-scrollbar,
.template-panel::-webkit-scrollbar,
.suggestions::-webkit-scrollbar {
width: 10px; 
}
.contacts::-webkit-scrollbar-thumb,
.techs::-webkit-scrollbar-thumb,
.column-content::-webkit-scrollbar-thumb,
.chat-window::-webkit-scrollbar-thumb,
.filterList::-webkit-scrollbar-thumb,
.number-tags::-webkit-scrollbar-thumb,
.modal-content::-webkit-scrollbar-thumb,
.edit-column::-webkit-scrollbar-thumb,
.edit-assign::-webkit-scrollbar-thumb,
.own-modal-list::-webkit-scrollbar-thumb,
.dropdown::-webkit-scrollbar-thumb,
.template-list::-webkit-scrollbar-thumb,
.template-panel::-webkit-scrollbar-thumb,
.suggestions::-webkit-scrollbar-thumb {
background: #abbdcc;
}
.contacts::-webkit-scrollbar-thumb:hover,
.techs::-webkit-scrollbar-thumb:hover,
.column-content::-webkit-scrollbar-thumb:hover,
.chat-window::-webkit-scrollbar-thumb:hover,
.filterList::-webkit-scrollbar-thumb:hover,
.number-tags::-webkit-scrollbar-thumb:hover,
.modal-content::-webkit-scrollbar-thumb:hover,
.edit-column::-webkit-scrollbar-thumb:hover,
.edit-assign::-webkit-scrollbar-thumb:hover,
.own-modal-list::-webkit-scrollbar-thumb:hover,
.dropdown::-webkit-scrollbar-thumb:hover,
.template-list::-webkit-scrollbar-thumb:hover,
.template-panel::-webkit-scrollbar-thumb:hover,
.suggestions::-webkit-scrollbar-thumb:hover  {
background: #abbdcc; 
}
</style>
