<template>
  <div class="techs">
    <span :data-username="username" id="username"></span>
    <div class="column-header">
      <h3 class="column-title">
        <a href="#" id="techsTab" class="tech-tab-link active" @click.prevent="switchTab('techs')">Techs</a>
        <a href="#" id="myTechsTab" class="tech-tab-link" @click.prevent="switchTab('myTechs')">My techs</a> 👷
      </h3>
      <span class="contact-search">
        <div class="tech-search">
          <input type="text" id="techSearchInput" class="filter-search" placeholder="Filter techs..." v-model="searchQuery">
          <a id="reloadTechs" class="clickable-emoji" title="Reload techs" @click="reloadTechs">🔄</a>
          <a id="techOwnerButton" v-if="state.isAdmin"  @click="showTechModal = true" class="clickable-emoji" title="Edit owners">📒</a>
          <a id="marketBlastButton" v-if="state.isAdmin" @click="showBlastModal = true" class="clickable-emoji" title="Text blast market">🏙️</a>
        </div>
      </span>
    </div>

    <AppModal :show="showTechModal" @close="showTechModal = false">
      <div class="edit-market-owners">
        <div class="modal-title">Manage market owners</div>
        <select v-if="markets.length" v-model="selectedMarket" class="dropdown">
          <option v-for="market in markets" :key="market" :value="market">{{ market }}</option>
        </select>
        <select v-if="state.userList.length" v-model="selectedMktUser" class="dropdown">
          <option v-for="user in state.userList" :key="user" :value="user">{{ user }}</option>
        </select>
        <button class="dropdown" @click="submitMarketOwner">Submit</button>
        <div class="modal-title">Manage tech owners</div>
        <span class="contact-search">
          <div class="tech-search">
            <input type="text" id="techSearchInput" class="filter-search" placeholder="Filter techs..." v-model="ownModalQuery">
          </div>
        </span>
        <div class="own-modal-list">
          <ul id="ownTechBook">
            <li 
              v-for="tech in filteredOwnTechs" 
              :key="tech.tech_id.toString()" 
              @click="handleTechOwnClick(tech)" 
              class="contact-item">
                <span v-if="selectedOwnTech.includes(tech.tech_id)">✔️</span>
                {{ tech.tech_name }}
              <span v-if="tech.owner" class="owner-text"> ({{ tech.owner }})</span>
            </li>
          </ul>
        </div>
        <select v-if="state.userList.length" v-model="selectedUser" class="dropdown">
          <option v-for="user in state.userList" :key="user" :value="user">{{ user }}</option>
        </select>
        <button class="dropdown" @click="submitTechOwner">Submit</button>
      </div>
    </AppModal>

    <AppModal :show="showBlastModal" @close="showBlastModal = false">
      <div class="edit-market-owners">
        <div class="modal-title">Tech text blasts</div>
        <select v-if="markets.length" v-model="selectedMarket" class="dropdown">
          <option v-for="market in markets" :key="market" :value="market">{{ market }}</option>
        </select> 
        <button @click="selectedZones = []" class="modal-button">Clear zones</button>
        <div class="zone-modal-container">
          <div class="zone-modal-list">
            <ul v-if="selectedMarket">
              <li v-for="(zoneId, zoneName) in zoneList[selectedMarket]" :key="zoneId"  
                @click="handleZoneClick(zoneId)" class="contact-item">>
                <span v-if="selectedZones.includes(zoneId)">✔️</span>
                {{ zoneName }}
              </li>
            </ul>
          </div>
          <textarea v-model="techGroupMsg" class="zone-textarea" placeholder="Enter your message here..."></textarea>
        </div>
        <label>
          <input type="checkbox" v-model="online"> Online
        </label>
        <label>
          <input type="checkbox" v-model="offline"> Offline
        </label>
        <button @click="groupTechMsg" class="modal-button">Send Message</button>
      </div>
    </AppModal>

    <div class="column-content">

      <ul id="techBook">
          <li 
            v-for="tech in filteredTechs.slice(0, 100)" 
            :key="tech.tech_id.toString()" 
            @click="handleTechClick(tech)" 
            class="contact-item">
            <span v-if="tech.isSaved">📌</span>
            <span v-if="tech.isHighlighted" class="bell-icon">🔔</span>
            <span v-if="tech.isTagged" class="tag-icon">@</span>
            <span :class="{ 'bold-text': tech.isHighlighted || tech.isTagged, 'offline': !tech.online && state.instanceName !== 'Onboarding' }">
            {{ tech.tech_name }}<span v-if="tech.battery"> 🔋</span></span>
            <span v-if="tech.owner" class="owner-text"> ({{ tech.owner }})</span>
            <span v-if="tech.online" class="owner-text"> (WOs: {{ tech.wos }})</span>
          </li>
      </ul>

    </div>
    <input type="text" v-if="state.selectedTechs === 'techs'" id="techOwnerSearchInput" class="filter-search" placeholder="Filter owner..." v-model="ownerQuery">
  </div>
</template>

<script>
import wsApi from '@/api/ws.js';
import { state, updateLastViewedTimestamp, modalTechsList, updateTechBook } from '@/store.js';
import { ref, computed } from 'vue';
import AppModal from '@/components/AppModal.vue';

export default {
  name: 'TechsList',
  components: {
    AppModal
  },
  data() {
    return {
      selectedTech: null,
      latestTimestamp: null,
      recipientValue: '',
      searchQuery: '',
      ownModalQuery: '',
      ownerQuery: '',
      showTechModal: false,
      showBlastModal: false,
      techGroupMsg: '',
      online: false,
      offline: false
    };
  },
  setup() {
    // const techsList = ref(JSON.parse(localStorage.getItem('techBook')) || []);
    const zoneList = ref(JSON.parse(localStorage.getItem('zoneList')) || {});
    // const modalTechsList = ref(JSON.parse(localStorage.getItem('techBook')) || []);
    // const myTechsList = ref([]);
    const selectedOwnTech = ref([]);
    const selectedMarket = ref('');
    const selectedUser = ref('');
    const selectedZones = ref([]);
    const selectedMktUser = ref('');
    const ownedTechSelect = ref('');
    const ownsTechSelect = ref('');
    // const techBook = ref([]);
    
    const handleTechClick = (tech) => {
      if (state.chatTitleBar.phone !== tech.tech_id) {
        state.mainMessage = '';
      }
      state.chatTitleBar.label = tech.tech_name;
      state.chatTitleBar.phone = tech.tech_id;
      state.chatTitleBar.wos = [];
      state.chatTitleBar.isGroup = false;
      wsApi.loadChat(tech.tech_id.toString());
      updateLastViewedTimestamp(tech.tech_id.toString());
      const index = state.tagList.indexOf(tech.tech_id.toString());
      if (index > -1) {
        state.tagList.splice(index, 1);
        const notifPayload = {
          action: 'syncNotifs',
          content: { tags: state.tagList }
        };
        wsApi.send(JSON.stringify(notifPayload));
      }
      const readPayload = {
        action: 'syncReads',
        content: state.lastViewed
      };
      wsApi.send(JSON.stringify(readPayload));
      const payload = {
        'action': 'getTechs',
        'content': {}
      };
      wsApi.send(JSON.stringify(payload));
    };

    const switchTab = (tab) => {
      if (tab === 'techs' ) {
        state.selectedTechs = "techs";
        document.querySelector('.tech-tab-link.active').classList.remove('active');
        document.getElementById('techsTab').classList.add('active');
      } else if (tab === 'myTechs') {
        state.selectedTechs = "myTechs";
        document.querySelector('.tech-tab-link.active').classList.remove('active');
        document.getElementById('myTechsTab').classList.add('active');
      }
    };

    const savedTechs = computed(() => {
        const savedChats = JSON.parse(localStorage.getItem('savedChats')) || [];
        return state.techsList.filter(tech => savedChats.includes(tech.tech_id.toString()));
    });

    const myTechs = computed(() => {
        const savedChats = JSON.parse(localStorage.getItem('savedChats')) || [];
        return state.techsList.filter(tech => !savedChats.includes(tech.tech_id.toString()));
    });

    const handleMessageReceived = (data) => {
      if (data.action === "listTechs") {
        // localStorage.setItem("techBook", JSON.stringify(data.content));
        // techsList.value = [...data.content];
        updateTechBook(data.content);
      } else if (data.action === "zoneList") {
        localStorage.setItem("zoneList", JSON.stringify(data.content));
        zoneList.value = data.content;
      }
    };

    return {
      state,
      zoneList,
      switchTab,
      handleTechClick,
      savedTechs,
      myTechs,
      modalTechsList,
      handleMessageReceived,
      selectedOwnTech,
      selectedMarket,
      selectedUser,
      selectedZones,
      selectedMktUser,
      ownedTechSelect,
      ownsTechSelect,
    };
  },

computed: {

    // sortedTechs() {
    //   let contents = state.techsList;
    //   if (state.selectedTechs === "myTechs" && Array.isArray(contents)) {
    //     contents = contents.filter(item => item.owner === state.username);
    //   } 
    //   const pinnedWithTimestamps = contents.filter(tech => state.savedChats.includes(tech.tech_id.toString()) && tech.timestamp !== "");
    //   const pinnedWithoutTimestamps = contents.filter(tech => state.savedChats.includes(tech.tech_id.toString()) && tech.timestamp === "");
    //   const unpinned = contents.filter(tech => !state.savedChats.includes(tech.tech_id.toString()));
    //   pinnedWithTimestamps.sort((a, b) => b.timestamp.localeCompare(a.timestamp));
    //   unpinned.sort((a, b) => (b.timestamp || "").localeCompare(a.timestamp || ""));
    //   return [...pinnedWithTimestamps, ...pinnedWithoutTimestamps, ...unpinned].map(tech => {
    //     const isSaved = state.savedChats.includes(tech.tech_id.toString());
    //     const lastViewedStored = localStorage.getItem('lastViewed');
    //     const lastViewedForTech = lastViewedStored ? JSON.parse(lastViewedStored)[tech.tech_id.toString()] : null;
    //     const lastViewedTimestamp = lastViewedForTech ? new Date(lastViewedForTech) : null;
    //     const techTimestamp = tech.timestamp ? new Date(tech.timestamp) : null;
    //     const isHighlighted = tech.history && (!lastViewedTimestamp || techTimestamp > lastViewedTimestamp);
    //     const isTagged = state.tagList.includes(tech.tech_id.toString());
    //     return {
    //       ...tech,
    //       tech_name: tech.tech_name,
    //       tech_id: tech.tech_id.toString(),
    //       phone: tech.tech_phone,
    //       timestamp: tech.timestamp,
    //       owner: tech.owner,
    //       wos: tech.wos,
    //       zones: tech.zones,
    //       battery: tech.battery,
    //       online: tech.online,
    //       isHighlighted: isHighlighted,
    //       isTagged: isTagged,
    //       isSaved: isSaved
    //     };
    //   });
    // },

      // test
      sortedTechs() {
        let contents = state.techsList;
        if (state.selectedTechs === "myTechs" && Array.isArray(contents)) {
          contents = contents.filter(item => item.owner === state.username);
        }
        contents.sort((a, b) => (b.timestamp || "").localeCompare(a.timestamp || ""));
        return contents;
      },

      // test
      filteredTechs() {
        let techs = this.sortedTechs;
        if (state.selectedTechs === "myTechs") {
          techs = techs.filter(item => item.owner === state.username);
        }
        const techTrimmedQuery = this.searchQuery.trim().toLowerCase();
        const ownerTrimmedQuery = this.ownerQuery.trim().toLowerCase();
        techs = techs.filter(tech => {
          const techNameMatch = techTrimmedQuery ? tech.tech_name.toLowerCase().includes(techTrimmedQuery) : true;
          const ownerMatch = tech.owner && ownerTrimmedQuery ? tech.owner.toLowerCase().includes(ownerTrimmedQuery) : !ownerTrimmedQuery;
          return techNameMatch && ownerMatch;
        });
        return techs.slice(0, 100).map(tech => ({
          ...tech,
          isSaved: state.savedChats.includes(tech.tech_id.toString()),
          isHighlighted: this.calculateIsHighlighted(tech),
          isTagged: state.tagList.includes(tech.tech_id.toString())
        }));
      },

    sortedModalTechs() {
      let contents = modalTechsList.value;
      if (state.selectedTechs === "myTechs" && Array.isArray(contents)) {
        contents = contents.filter(item => item.owner === state.username);
      } 
      const pinnedWithTimestamps = contents.filter(tech => state.savedChats.includes(tech.tech_id.toString()) && tech.timestamp !== "");
      const pinnedWithoutTimestamps = contents.filter(tech => state.savedChats.includes(tech.tech_id.toString()) && tech.timestamp === "");
      const unpinned = contents.filter(tech => !state.savedChats.includes(tech.tech_id.toString()));
      pinnedWithTimestamps.sort((a, b) => b.timestamp.localeCompare(a.timestamp));
      unpinned.sort((a, b) => (b.timestamp || "").localeCompare(a.timestamp || ""));
      return [...pinnedWithTimestamps, ...pinnedWithoutTimestamps, ...unpinned].map(tech => {
        const techLastViewedTimestamp = state.lastViewed[tech.tech_id.toString()] || null;
        const isHighlighted = tech.history && (!techLastViewedTimestamp || tech.timestamp > techLastViewedTimestamp);
        return {
          ...tech,
          tech_name: tech.tech_name,
          tech_id: tech.tech_id.toString(),
          phone: tech.tech_phone,
          timestamp: tech.timestamp,
          owner: tech.owner,
          wos: tech.wos,
          zones: tech.zones,
          online: tech.online,
          isHighlighted: isHighlighted,
          history: tech.history
        };
      });
    },

  // filteredTechs() {
  //   let techs = this.sortedTechs;
  //   if (state.selectedTechs === "myTechs") {
  //     techs = techs.filter(item => item.owner === state.username);
  //   } 
  //   const techTrimmedQuery = this.searchQuery.trim().toLowerCase();
  //   const ownerTrimmedQuery = this.ownerQuery.trim().toLowerCase();
  //   return techs.filter(tech => {
  //     const techNameMatch = techTrimmedQuery ? tech.tech_name.toLowerCase().includes(techTrimmedQuery) : true;
  //     const ownerMatch = tech.owner && ownerTrimmedQuery ? tech.owner.toLowerCase().includes(ownerTrimmedQuery) : !ownerTrimmedQuery;
  //     return techNameMatch && ownerMatch;
  //   });
  // },
  filteredOwnTechs() {
    let techs = this.sortedModalTechs;
    const techTrimmedQuery = this.ownModalQuery.trim().toLowerCase();
    return techs.filter(tech => {
        const techNameMatch = techTrimmedQuery ? tech.tech_name.toLowerCase().includes(techTrimmedQuery) : true;
        return techNameMatch;
    });
  },
  markets() {
    return Object.keys(JSON.parse(localStorage.getItem('zoneList')) || {});
  }
},
  
  methods: {
    // test
    calculateIsHighlighted(tech) {
      const lastViewedStored = localStorage.getItem('lastViewed');
      const lastViewedForTech = lastViewedStored ? JSON.parse(lastViewedStored)[tech.tech_id.toString()] : null;
      const lastViewedTimestamp = lastViewedForTech ? new Date(lastViewedForTech) : null;
      const techTimestamp = tech.timestamp ? new Date(tech.timestamp) : null;
      return tech.history && (!lastViewedTimestamp || techTimestamp > lastViewedTimestamp);
    },
    reloadTechs() {
      var payload = {
        action: 'reloadTechs',
        content: {}
      };
      wsApi.send(JSON.stringify(payload));
    },
    submitMarketOwner() {
      const payload = {
        action: "assignMarket",
        content: {
          owner: this.selectedMktUser,
          market: this.selectedMarket
        }
      };
      wsApi.send(JSON.stringify(payload));
      this.selectedMarket = '';
      this.selectedMktUser = '';
      this.showTechModal = false;
    },
    handleTechOwnClick(tech) {
      if (this.selectedOwnTech.includes(tech.tech_id)) {
        this.selectedOwnTech = this.selectedOwnTech.filter(id => id !== tech.tech_id);
      } else {
        this.selectedOwnTech.push(tech.tech_id);
      }
    },
    handleZoneClick(zoneid) {
      if (this.selectedZones.includes(zoneid)) {
        this.selectedZones = this.selectedZones.filter(id => id !== zoneid);
      } else {
        this.selectedZones.push(zoneid);
      }
    },
    async assignUser(phone, owner) {
      return new Promise((resolve, reject) => {
        const payload = {
          action: "assignUser",
          content: {
            owner: owner,
            number: phone
          }
        };
        wsApi.send(JSON.stringify(payload), (error) => {
          if (error) reject(error);
          else resolve();
        });
      });
    },
    async submitTechOwner() {
      if (!this.selectedUser || this.selectedOwnTech.length === 0) {
        console.error("No user selected or no techs selected");
        return;
      }
      try {
        await Promise.all(this.selectedOwnTech.map(tech_id => this.assignUser(tech_id, this.selectedUser)));
        this.selectedOwnTech = [];
      } catch (error) {
        console.error("Error assigning techs:", error);
      }
      this.selectedUser = '';
      this.selectedOwnTech = [];
      this.showTechModal = false;
    },
    async groupTechMsg() {
      if (!this.selectedMarket || this.selectedZones.length === 0 || this.techGroupMsg === '' || (this.online === false && this.offline === false)) {
        return;
      }
      const recipientIds = state.techsList
        .filter(tech => {
          if (this.online && !this.offline && !tech.online) return false;
          if (this.offline && !this.online && tech.online) return false;
          // check for null
          return tech.zones && this.selectedZones.some(zoneId => tech.zones.includes(zoneId));
        })
        .map(tech => tech.tech_id);
      for (const recipientId of recipientIds) {
        console.log("Sending message to Tech ID:", recipientId);
        const payload = {
          action: 'sendMsg',
          content: {
            phone_number: recipientId.toString(),
            twilio_number: state.selectedOutgoing,
            message: this.techGroupMsg
          }
        };
        wsApi.send(JSON.stringify(payload));
      }
      this.selectedMarket = '';
      this.techGroupMsg = '';
      this.selectedZones = [];
      this.online = false;
      this.offline = false;
      this.showBlastModal = false;
    }
  },

  mounted() {
    wsApi.on('messageReceived', this.handleMessageReceived);
  }
}
</script>

<style scoped>
.techs {
    flex: 1;
    width: 20%;
    height: 85%;
    padding: 10px;
    border: none;
    position: relative;
}
.techs::before {
    content: "";
    position: absolute;
    top: 0; 
    left: 0;
    right: 0;
    height: 55px;
    background-color: #4682b4;
}

.techs ul {
    list-style-type: none;
    padding: 0;
}

.column-header {
    width: 100%;
    top: 0;
    z-index: 0;
    position: sticky;
}

.column-title {
    background-color: #4682b4;
    color: #fff;
    white-space: nowrap;
}

.tech-tab-link {
    text-decoration: none;
    color: inherit;
    padding: 10px;
    cursor: pointer;
}
.tech-tab-link.active {
    font-weight: bold;
    border: 2px solid #99b3c9;
    background: #99b3c9;
}

.filter-search {
    background-color: #d3d3d3;
    width: 40%
}

.clickable-emoji {
    cursor: pointer;
    top: 0;
    left: 0;
}

.tag-icon {
  color: #c02c2c;
  font-weight: bold;
}

.column-content {
    height: calc(90%);
    overflow-y: auto;
    overflow-x: hidden;
}

.contact-item {
  padding: 5px;
  font-size: 12px;
  cursor: pointer;
}

.contact-item:hover {
  background-color: #abbdcc;
}

.bold-text {
    font-weight: bold;
}

.dropdown {
  color: #fff;
  padding: 10px;
  font-size: 16px;
  text-align: center;
  background-color: #529dd6;
  border: 3px solid #4682b4;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.own-modal-list {
  width: 500px;
  height: 250px;
  border: 1px solid #fff;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 5px;
}

.zone-modal-list {
  width: 400px;
  height: 400px;
  border: 1px solid #fff;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 5px;
  flex: 1;
  overflow-y: auto;
}

.zone-modal-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
}

.zone-textarea {
  flex: 2;
  resize: vertical;
  background-color: #4682b4;
  border: 1px solid #fff;
  border-color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  color: #fff;
  width: 120px;
  height: 380px;
}
.zone-textarea::placeholder {
  color: #fff;
  opacity: 1; 
}

.modal-button {
  display: inline-block;
  padding: 10px 15px;
  border: 1px solid #99b3c9;
  background-color: #529dd6;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
}
.modal-button:hover {
  background-color: #4682b4;
}

.offline {
  color: #727272;
  filter: grayscale(100%);
}
</style>
