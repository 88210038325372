import mitt from 'mitt';
import { state } from '@/store.js';

let webSocketURL;
if (process.env.NODE_ENV === 'development') {
    webSocketURL = 'ws://localhost:3000/ws';
} else {
    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    webSocketURL = `${protocol}//${window.location.host}/ws`;
}

let webSocketInstance = null;
const eventBus = mitt();

function initializeWebSocket() {
    if (!webSocketInstance || webSocketInstance.readyState === WebSocket.CLOSED) {
        webSocketInstance = new WebSocket(webSocketURL);
        webSocketInstance.addEventListener('open', onWebSocketOpen);
        webSocketInstance.addEventListener('close', onWebSocketClose);
        webSocketInstance.addEventListener('error', onWebSocketError);
        webSocketInstance.onmessage = onWebSocketMessage;
    }
}

function connectWebSocket() {
    initializeWebSocket();
}

function onWebSocketOpen() {
    console.log('WebSocket connection opened');
    const actionRequest = {
        action: 'Init'
    };
    webSocketInstance.send(JSON.stringify(actionRequest));
}

function sendWebSocketMessage(content) {
    if (!webSocketInstance || webSocketInstance.readyState !== WebSocket.OPEN) {
        console.error("WebSocket is not open. Cannot send message.");
        return;
    }
    webSocketInstance.send(content);
}

function isWebSocketConnected() {
    return webSocketInstance && webSocketInstance.readyState === WebSocket.OPEN;
}

function onWebSocketClose() {
    console.log('WebSocket disconnected');
    setTimeout(() => {
        initializeWebSocket();
    }, 2000);
}

function onWebSocketError() {
    console.log('WebSocket error');
    eventBus.emit('error', '');
}

function onWebSocketMessage(event) {
    const data = JSON.parse(event.data);
    eventBus.emit('messageReceived', data);
}

function loadChat(phoneNumber) {
    state.selectedPhoneNumber = phoneNumber;
    const payload = {
        action: 'loadChat',
        content: {
            phone_number: phoneNumber
        }
    };
    webSocketInstance.send(JSON.stringify(payload));
}

function getWebSocket() {
    return webSocketInstance;
}

const webSocketAPI = {
    connectWebSocket,
    on: eventBus.on,
    off: eventBus.off,
    loadChat,
    send: sendWebSocketMessage,
    isConnected: isWebSocketConnected,
    getWebSocket,
};

export default webSocketAPI;