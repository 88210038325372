<template>
  <div class="contacts">
    <span :data-username="username" id="username"></span>
    <div class="column-header">
      <h3 class="column-title">
        <a href="#" id="contactsTab" class="tab-link active" @click.prevent="switchTab('contacts')">Contacts</a>
        <a href="#" id="groupsTab" class="tab-link" @click.prevent="switchTab('groups')">Groups</a> 👥
      </h3>
      <span class="contact-search">
        <input v-if="state.selectedChat === 'contacts'" type="text" id="contactSearchInput" @input="filterList" v-model="state.contactsFilter" class="filter-search" placeholder="Filter contacts...">
        <input v-if="state.selectedChat === 'groups'" type="text" id="groupSearchInput" @input="filterGroups" v-model="state.groupFilter" class="filter-search" placeholder="Filter groups...">
        <button v-if="state.selectedChat === 'contacts'" @click="showContactModal = true" class="add-contact">+Contact</button>
        <button v-if="state.selectedChat === 'groups'" @click="showGroupModal = true" class="add-contact">+Group</button>
        <a class="clickable-emoji" @click="clearNotifs" title="Clear notifications">🔔</a>
        <a class="clickable-emoji" v-if="state.isAdmin && state.selectedChat === 'contacts'" @click="showEditModal = true" title="Edit contacts">📒</a>
        <a class="clickable-emoji" v-if="state.isAdmin && state.selectedChat === 'groups'" @click="editGroupModal = true" title="Edit groups">📒</a>

        <AppModal :show="showContactModal" @close="showContactModal = false">
          <h2>Add contact</h2>
          <input type="text" v-model="contactLabel" class="contact-field" placeholder="Name" required>
          <br>
          <input type="text" v-model="contactPhone" class="contact-field" placeholder="Phone" required>
          <br>
          <button @click="submitContact" class="modal-button">Submit</button>
        </AppModal>

        <div class="modal-container">
          <AppModal :show="showGroupModal" :key="reloadEditModal" @close="showGroupModal = false">
            <h2>Create Group</h2>
            <input type="text" v-model="groupName" placeholder="Group name" class="filter-field"/><br>
            <textarea v-model="numbersInput" @keyup="handleNumberInput" @paste="handlePaste" rows="3" class="note-field" placeholder="Enter numbers separated by commas"></textarea><br>
            <input type="text" v-model="contactsFilter" class="filter-field" placeholder="Filter contacts..." @input="filterGroupList('contacts')" /><br>
            <input type="text" v-model="techFilter" class="filter-field" placeholder="Filter techs..." @input="filterGroupList('techs')" />
            <div id="fileName"></div>
            <label class="file-upload">
              <input type="file" id="csvFileInput" @change="displaySelectedFile" accept=".csv">
              Choose CSV
            </label>
            <button @click="handleCSVUpload" class="modal-button">Upload CSV</button>
            <button @click="submitGroup" class="modal-button">Save group</button><br>
            <div id="numbersTags" class="number-tags">
              <span v-for="item in numbersArray" :key="item" class="tag-remove" @click="removeNumber(item)">
                {{ item.split(':')[0] }}
              </span>
            </div>
            <span v-if="!numbersArray.length" class="tag-placeholder">Enter or search for numbers, or upload a CSV<br>
              of name & phone per row (no titles)</span>
          </AppModal>
          
          <ul id="contactsList" class="filterList" :class="{ visible: shouldShowFilterList }" v-if="showGroupModal && contactsFilter">
            <li v-for="contact in filteredContacts" :key="contact.phone" class="tag-add" @click="addToNumbers(contact.label, contact.phone)">
              {{ contact.label }}
            </li>
          </ul>
          <ul id="techList" class="filterList" :class="{ visible: shouldShowFilterList }" v-if="showGroupModal && techFilter">
            <li v-for="tech in filteredTechs" :key="tech.tech_id" class="tag-add" @click="addToNumbers(tech.tech_name, tech.tech_id.toString())">
              {{ tech.tech_name }}
            </li>
          </ul>
        </div>

        <AppModal :show="editGroupModal" @close="editGroupModal = false">
            <h2>Edit groups</h2>
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="group in state.groupBook" :key="group.name">
                        <td>{{ group.name }}</td>
                        <td><span class="clickable-emoji" @click="deleteGroup(group.name)">🗑️</span></td>
                    </tr>
                </tbody>
            </table>
        </AppModal>

        <AppModal :show="showEditModal" @close="showEditModal = false">
            <h2>Edit Contacts</h2>
            <table>
                <thead>
                    <tr>
                        <th>Phone</th>
                        <th>Label</th>
                        <th>Owner</th>
                        <th>Blocked</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="contact in state.contactsBook" :key="contact.phone">
                        <td>{{ contact.phone }}</td>
                        <td><input class="modify-field" v-model="contact.label" @blur="updateContactLabel(contact.phone, contact.label)" /></td>
                        <td>
                            <select class="modify-field" v-model="contact.owner" @change="assignUser(contact.phone, contact.owner)">
                                <option v-for="user in state.userList" :key="user" :value="user">{{ user }}</option>
                            </select>
                        </td>
                        <td>
                          <span class="clickable-emoji" @click="toggleBlock(contact.phone)">
                            <span v-if="contact.block">🙅</span>
                            <span v-else>✔️</span>
                          </span>
                        </td>
                        <td><span class="clickable-emoji" @click="deleteContact(contact.phone)">🗑️</span></td>
                    </tr>
                </tbody>
            </table>
        </AppModal>

      </span>
    </div>

    <div class="column-content">
      <div v-if="state.selectedChat === 'contacts'" id="contactsContent" class="tab-content">
        <ul id="contactsBook">
         <li v-for="contact in sortedContacts.slice(0, 100)" :key="contact.phone" @click="handleContactClick(contact)" class="contact-item">
            <span v-if="!contact.block">
              <span v-if="contact.isHighlighted" class="bell-icon">🔔</span>
              <span v-if="contact.isTagged" class="tag-icon">@</span>
              <span v-if="state.savedChats.includes(contact.phone)">📌</span>
              <span :class="{ 'bold-text': contact.isHighlighted || contact.isTagged }">{{ contact.label }}</span>
              <span v-if="contact.owner" class="owner-text"> ({{ contact.owner }})</span>
            </span>
          </li>
        </ul>
      </div>
       <div v-if="state.selectedChat === 'groups'" id="groupsContent" class="tab-content">
        <ul id="groupBook">
          <li v-for="group in sortedGroups" :key="group.name" @click="handleGroupClick(group)" class="contact-item">
            <span v-if="state.savedChats.includes(group.name)">📌</span>
            {{ group.name }}
          </li>
        </ul>
      </div>
    </div>

    <input v-if="state.selectedChat === 'contacts'" type="text" id="ownerSearchInput" class="filter-search" v-model="state.ownerFilter" placeholder="Filter owner...">
  </div>
</template>

<script>
import wsApi from '@/api/ws.js';
import { state, updateLastViewedTimestamp, clearNotifications } from '@/store.js';
import { computed } from 'vue';
import AppModal from '@/components/AppModal.vue';

export default {
  computed: {
    shouldShowFilterList() {
      return this.showGroupModal && (this.techFilter || state.contactsFilter);
    },
    sortedGroups() {
      return [...this.filteredGroups].sort((a, b) => {
        const aIsPinned = state.savedChats.includes(a.name);
        const bIsPinned = state.savedChats.includes(b.name);
        if (aIsPinned && !bIsPinned) return -1;
        if (!aIsPinned && bIsPinned) return 1;
        return 0;
      });
    },
    savedContacts() {
      return this.contacts.filter(contact => this.savedChats.includes(contact.phone));
    },
    otherContacts() {
      return this.contacts.filter(contact => !this.savedChats.includes(contact.phone));
    },

    // new
    sortedContacts() {
      let contacts = this.filteredItems.slice(0, 100);
      return contacts.map(contact => {
        const lastViewedStored = localStorage.getItem('lastViewed');
        const lastViewedForContact = lastViewedStored ? JSON.parse(lastViewedStored)[contact.phone] : null;
        const lastViewedTimestamp = lastViewedForContact ? new Date(lastViewedForContact) : new Date('1970-01-01T00:00:00Z');
        const contactTimestamp = new Date(contact.timestamp);
        return {
          ...contact,
          isHighlighted: contact.history && contactTimestamp > lastViewedTimestamp,
          isTagged: state.tagList.includes(contact.phone),
          block: contact.block,
          label: contact.label,
          owner: contact.owner,
          phone: contact.phone
        };
      }).sort((a, b) => {
        const aIsPinned = state.savedChats.includes(a.phone);
        const bIsPinned = state.savedChats.includes(b.phone);
        const aHistory = a.history === true;
        const bHistory = b.history === true;
        if (aHistory && !bHistory) return -1;
        if (!aHistory && bHistory) return 1;
        if (aIsPinned && !bIsPinned) return -1;
        if (!aIsPinned && bIsPinned) return 1;
        return 0;
      });
    },
    filteredGroups() {
      const labelFilterValue = state.groupFilter.trim().toLowerCase();
      return state.groupBook.filter(item => {
        return labelFilterValue ? item.name.toLowerCase().includes(labelFilterValue) : true;
      });
    }
  },
  components: {
    AppModal
  },
  data() {
    return {
      showContactModal: false,
      contactLabel: '',
      contactPhone: '',
      showGroupModal: false,
      showEditModal: false,
      editGroupModal: false,
      groupName: '',
      numbersInput: '',
      techFilter: '',
      numbersArray: [],
      selectedFile: null,
      techsArray: [],
      reloadEditModal: 0
    };
  },
  setup() {
    const filteredItems = computed(() => {
      const labelFilterValue = state.contactsFilter.trim().toLowerCase();
      const ownerFilterValue = state.ownerFilter.trim().toLowerCase();
      return state.contactsBook.filter(item => {
        const labelMatch = labelFilterValue ? item.label.toLowerCase().includes(labelFilterValue) : true;
        const ownerMatch = ownerFilterValue ? item.owner?.toLowerCase().includes(ownerFilterValue) : true;
        return labelMatch && ownerMatch;
      });
    });

    return {
      state,
      filteredItems,
      updateLastViewedTimestamp,
      clearNotifications
    };
  },
  methods: {
      clearNotifs() {
        clearNotifications();
        const payload = {
          action: 'loadContacts',
          content: {}
        };
        wsApi.send(JSON.stringify(payload));
      },
      handleContactClick(phone) {
        if (state.chatTitleBar.phone !== phone.phone) {
          state.mainMessage = '';
        }
        state.chatTitleBar.label = phone.label;
        state.chatTitleBar.phone = phone.phone;
        state.chatTitleBar.wos = phone.wos;
        state.chatTitleBar.isGroup = false;
        wsApi.loadChat(phone.phone);
        updateLastViewedTimestamp(phone.phone);
        const index = state.tagList.indexOf(phone.phone);
        if (index > -1) {
          state.tagList.splice(index, 1);
          const notifPayload = {
            action: 'syncNotifs',
            content: { tags: state.tagList }
          };
          wsApi.send(JSON.stringify(notifPayload));
        }
        const payload = {
          action: 'loadContacts',
          content: {}
        };
        wsApi.send(JSON.stringify(payload));
        const readPayload = {
          action: 'syncReads',
          content: state.lastViewed
        };
        wsApi.send(JSON.stringify(readPayload));
      },
      // new
      switchTab(tab) {
        if (tab === 'contacts') {
          state.selectedChat = "contacts";
          document.querySelector('.tab-link.active').classList.remove('active');
          document.getElementById('contactsTab').classList.add('active');
        } else if (tab === 'groups') {
          state.selectedChat = "groups";
          document.querySelector('.tab-link.active').classList.remove('active');
          document.getElementById('groupsTab').classList.add('active');
        }
      },
      // new
      handleMessageReceived(data) {
        if (data.action === "loadHistory") {
          localStorage.setItem("contactsBook", JSON.stringify(data.content));
          state.contactsBook = [...data.content];
          
        } else if (data.action === "loadGroups") {
          localStorage.setItem("groupBook", JSON.stringify(data.content));
          state.groupBook = [...data.content];
        }
      },
      // new
      handleGroupClick(group) {
        if (state.chatTitleBar.phone !== group.phone) {
          state.mainMessage = '';
        }
        console.log(group)
        state.chatTitleBar.label = group.name;
        state.chatTitleBar.phone = group.name;
        state.chatTitleBar.wos = [];
        state.chatTitleBar.isGroup = true;
        wsApi.loadChat(group.name);
      },
      submitContact() { // create a new contact
        let phone = this.contactPhone.replace(/[^0-9+]/g, '');
        if (this.contactLabel === "") {
          this.contactLabel = phone;
        }
        if (phone !== "") {
          if (phone.length === 10) {
            phone = '+1' + phone;
          } else if (phone.length === 11) {
            phone = "+" + phone;
          } else {
            alert("Invalid number! Try again (it should be 10 digits, or have a leading +1)");
            return;
          }
          const payload = {
            action: 'addContact',
            content: {
              label: this.contactLabel,
              phone: phone
            }
          };
          wsApi.send(JSON.stringify(payload));
          this.contactLabel = '';
          this.contactPhone = '';
          state.showNoteModal = false;
        } else {
          alert("Please enter a phone number!");
        }
      },
      addToNumbers(label, number) { // add to array of numbers used to compose a group
          const item = `${label}:${number}`;
          if (!this.numbersArray.includes(item)) {
              this.numbersArray.push(item);
          }
      },
      submitGroup() { // create a new group
        if (!this.groupName) {
          alert('Please enter a group name');
          return;
        }
        const members = this.numbersArray.map(item => {
          const [name, number] = item.split(':');
          return {
            name: name,
            number: number
          };
        });
        const payload = {
          action: "saveGroup",
          content: {
            name: this.groupName,
            members: members
          }
        };
        wsApi.send(JSON.stringify(payload));
        this.numbersArray = [];
        this.showGroupModal = false;
      },
      toggleBlock(phone) {
        const payload = {
          action: "toggleBlock",
          content: {
            phone: phone
          }
        };
        wsApi.send(JSON.stringify(payload));
      },
      handleNumberInput(event) {
        if (event.key === ',') {
          this.processInput();
        }
      },
      handlePaste(event) {
        const pastedText = (event.clipboardData || window.clipboardData).getData('text');
        this.numbersInput = pastedText;
        this.processInput(true);
      },
      processInput(isPaste = false) {
        let inputs = this.numbersInput.split(/[\n,]+/);
        let processedInputs = [];
        for (let i = 0; i < inputs.length; i++) {
          let input = inputs[i].trim();
          if (input) {
            this.processSingleNumber(input);
            processedInputs.push(input);
          }
        }
        if (isPaste || processedInputs.length === inputs.length) {
          this.numbersInput = "";
        } else {
          this.numbersInput = inputs[inputs.length - 1];
        }
      },
      processSingleNumber(input) {
        input = input.replace(/^(\+)?\D+/g, "$1").replace(/\D+/g, "");
        if (input.length === 10) {
          input = "+1" + input;
        } else if (input.length === 11) {
          input = "+" + input;
        } else if (input.length > 12) {
          alert("Invalid number length. Please enter a valid number.");
          return;
        } else if (input.length < 10) {
          alert("Invalid number length. Please enter a valid number.");
          return;
        }
        if (input) {
          this.numbersArray.push(input+":"+input);
        }
      },
    removeNumber(item) {
      const index = this.numbersArray.indexOf(item);
      if (index !== -1) {
          this.numbersArray.splice(index, 1);
      }
    },
    filterGroupList(type) {
      let filterValue;
      if (type === 'contacts') {
        filterValue = state.contactsFilter.toLowerCase();
        this.filteredContacts = state.contactsBook.filter(contact => contact.label.toLowerCase().includes(filterValue));
      } else if (type === 'techs') {
        filterValue = this.techFilter.toLowerCase();
        this.filteredTechs = this.techsArray.filter(tech => tech.tech_name.toLowerCase().includes(filterValue));
      }
    },
    displaySelectedFile(event) {
      this.selectedFile = event.target.files[0];
      if (this.selectedFile) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const contents = e.target.result;
          this.parseCSV(contents);
        };
        reader.readAsText(this.selectedFile);
      } else {
        alert('Please select a CSV file.');
      }
    },
    async handleCSVUpload() {
      if (this.selectedFile) {
        try {
          const contents = await this.readFile(this.selectedFile);
          this.parseCSV(contents);
        } catch (err) {
          console.error("Error reading the file:", err);
        }
      }
    },
    readFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = event => resolve(event.target.result);
        reader.onerror = error => reject(error);
        reader.readAsText(file);
      });
    },
    parseCSV(contents) {
      const rows = contents.split("\n");
      rows.forEach(row => {
        const columns = row.split(",");
        let number = columns[1];
        const name = columns[0];
        number = number.replace(/[^\d+]/g, '');
        if (number) {
          const numDigits = number.replace(/[^\d]/g, '').length;
          if (numDigits === 10) {
            number = '+1' + number;
          } else if (numDigits === 11) {
          number = '+' + number;
          }
        }
        if (number && !this.numbersArray.includes(name+':'+number)) {
          this.numbersArray.push(name+':'+number);
        }
      });
    },
    deleteContact(phone) {
        const payload = {
            action: "deleteContact",
            content: {
                phone: phone
            }
        };
        wsApi.send(JSON.stringify(payload));
        this.reloadEditModal++;
    },
    deleteGroup(name) {
      var payload = {
        action: "deleteGroup",
        content: {
          name: name
        },
      };
      wsApi.send(JSON.stringify(payload));
      this.reloadGroupModal++;
    },
    assignUser(phone, owner) {
        const payload = {
            action: "assignUser",
            content: {
                owner: owner,
                number: phone
            }
        };
        wsApi.send(JSON.stringify(payload));
    },
    updateContactLabel(phone, label) {
        const payload = {
            action: 'addContact',
            content: {
                label: label,
                phone: phone
            }
        };
        wsApi.send(JSON.stringify(payload));
    },
    filterList() {
      const labelFilterValue = state.contactsFilter.trim().toLowerCase();
      const ownerFilterValue = state.ownerFilter.trim().toLowerCase();
      this.filteredItems = state.contactsBook.filter(item => {
        const labelMatch = labelFilterValue ? item.label.toLowerCase().includes(labelFilterValue) : true;
        const ownerMatch = ownerFilterValue ? item.owner?.toLowerCase().includes(ownerFilterValue) : true;
        return labelMatch && ownerMatch;
      });
    },
    filterGroups() {
      const labelFilterValue = state.groupFilter.trim().toLowerCase();
      this.filteredGroups = state.groupBook.filter(item => {
        const labelMatch = labelFilterValue ? item.name.toLowerCase().includes(labelFilterValue) : true;
        return labelMatch;
      });
    }
  },
  mounted() {
    this.techsArray = JSON.parse(localStorage.getItem('techBook')) || [];
    wsApi.on('messageReceived', this.handleMessageReceived);
  },
  beforeUnmount() {
    wsApi.off('messageReceived', this.handleMessageReceived);
  }
}
</script>

<style scoped>
.filter-search {
  background-color: #d3d3d3;
  width: 40%
}

.contacts {
  flex: 1;
  width: 20%;
  height: 85%;
  padding: 10px;
  border: none;
  position: relative;
}

.contacts:before {
  content: "";
  position: absolute;
  top: 0; 
  left: 0;
  right: 0;
  height: 55px;
  background-color: #4682b4;
}

.column-content {
  height: calc(90%); /* Subtract the height of the header */
  overflow-y: auto;
  overflow-x: hidden; 
}

.contacts ul {
  list-style-type: none;
  padding: 0;
}

.contact-item {
  padding: 5px;
  font-size: 12px;
  cursor: pointer;
}

.contact-item:hover {
  background-color: #abbdcc;
}

.tab-link {
  text-decoration: none;
  color: inherit;
  padding: 10px;
  cursor: pointer;
}

.tab-link.active {
  font-weight: bold;
  border: 2px solid #99b3c9;
  background: #99b3c9;
}

.add-contact {
  top:50%;
  background-color:#2781ca;
  color: #fff;
  border:none; 
  border-radius:2px; 
  padding:3px;
  padding-left: 10px;
  padding-right: 10px;
}
.add-contact:hover {
  background-color:#224e72;
  transition: 0.1s;
}

.column-title {
  background-color: #4682b4;
  color: #fff;
  white-space: nowrap;
}

.column-header {
  width: 100%;
  top: 0;
  z-index: 1;
  position: sticky;
}

.clickable-emoji {
  cursor: pointer;
  top: 0;
  left: 0;
}

.tag-icon {
  color: #c02c2c;
  font-weight: bold;
}

.contact-field {
  align-items: center;
  background-color: #4682b4;
  border: 1px solid #fff;
  color: #fff;
  border-color: #fff;
  width: 400px;
  padding: 10px 15px;
  border-radius: 5px;
}
.contact-field::placeholder {
  font-family: 'Victor Mono', monospace;
  color: #fff;
}

.modify-field {
  font-family: 'Victor Mono', monospace;
  align-items: center;
  background-color: #4682b4;
  border: 1px solid #fff;
  color: #fff;
  border-color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}
.modify-field::placeholder {
  font-family: 'Victor Mono', monospace;
  color: #fff;
}

.modal-button {
  display: inline-block;
  padding: 10px 15px;
  border: 1px solid #99b3c9;
  background-color: #529dd6;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
}
.modal-button:hover {
  background-color: #4682b4;
}

.filter-field {
  align-items: center;
  background-color: #4682b4;
  border: 1px solid #fff;
  color: #fff;
  border-color: #fff;
  width: 400px;
  padding: 10px 15px;
  border-radius: 5px;
}
.filter-field::placeholder {
  font-family: 'Victor Mono', monospace;
  color: #fff;
}

.note-field {
  background-color: #4682b4;
  border: 1px solid #fff;
  border-color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  color: #fff;
  width: 400px;
}
.note-field::placeholder {
  color: #fff;
  opacity: 1; 
}

.filterList {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  width: 550px;
  height: 150px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  background-color: #4682b4;
  border: 3px solid #529dd6;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 20;
  visibility: hidden;
  padding: 20px;
  border-radius: 5px;
}
.filterList.visible {
  visibility: visible;
}

.file-upload {
  display: inline-block;
  padding: 10px 15px;
  border: 1px solid #99b3c9;
  background-color: #529dd6;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
}
.file-upload:hover {
  background-color: #4682b4;
}
#csvFileInput {
  display: none;
}

.number-tags {
  border: 1px solid #529dd6;
  border-color: #fff;
  position: relative;
  text-align: left;
  height: 150px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px 15px;
  border-radius: 5px;
  z-index: 5;
}

.tag-add, .tag-remove {
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #ccc;
  margin: 2px;
  border-radius: 4px;
  line-height: 1.2;
  z-index: 6;
}
.tag-remove:hover {
  border-color: darkred;
}
.tag-add:hover {
  border-color: lightgreen;
}

.modal-container {
  position: relative;
}

.table {
  width: 100%;
  border-collapse: collapse;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
}

th, td {
  padding: 8px 12px;
  border: 1px solid #ddd;
}

.bold-text {
    font-weight: bold;
}
</style>